import { CssBaseline, ThemeProvider } from '@mui/material';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import Router from './routes/Router';
import './Global.css';
import { baselightTheme } from './theme/DefaultColors';

import { useEffect } from 'react';

import { useMain } from './views/hooks/useMain';

function App() {
  const routing = useRoutes(Router);
  const theme = baselightTheme;
  const navigate = useNavigate();

  const {
    fetchAOwner,
    setOwnerData,
    setActiveBranchId,
    getBranch,
    setBranchData,
  } = useMain();

  const getBranchData = async (id) => {
    try {
      const res = await getBranch(id);
      //console.log(res);
      if (res.statusCode === 200) setBranchData((pre) => ({ ...pre, ...res.data }));
    } catch (error) {}
  };

  const getData = async (id) => {
    try {
      const res = await fetchAOwner(id);
     // console.log("fetchAOwner api is calling");
      if (res?.statusCode === 200) {
        const owner = res?.data;
        if (owner?.activeStatus && owner?.activeStatus.toLowerCase() !== 'active') {
          localStorage.removeItem('salon-admin');
          setActiveBranchId(null);
          setBranchData({});
          setOwnerData({});
          navigate('/auth/login');
          return;
        }
        getBranchData(owner?.branch[0]?._id);
        localStorage.setItem('branchId', owner?.branch[0]?._id)
        localStorage.setItem('salon-admin', JSON.stringify(owner._id));
        setOwnerData(owner);
        setActiveBranchId(
          () => owner?.branch?.filter((item) => item?.activeStatus === 'APPROVED')[0]?._id,
        );
      } else {
        localStorage.removeItem('salon-admin');
        navigate('/auth/login');
      }
    } catch (error) {
      localStorage.removeItem('salon-admin');
      navigate('/auth/login');
    } finally {
    }
  };

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem('salon-admin'));

    if (id) {
      getData(id);
    } else {
      navigate('/auth/login');
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {routing}
    </ThemeProvider>
  );
}

export default App;
