// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import { useMain } from '../hooks/useMain';
// import star_img from '../../assets/images/star.png';
// import { Grid, InputLabel, Select, MenuItem, Button, Typography, Pagination, IconButton } from '@mui/material';
// import SearchImg from '../../assets/search.svg'; // Add correct path if needed
// import { Box } from '@mui/system';
// import { ClearIcon } from '@mui/x-date-pickers-pro';

// export const maskData = (value, isMasked) => {
//   if (isMasked) {
//     const maskLength = Math.ceil(value.length / 2);
//     return '*'.repeat(maskLength) + value.substring(maskLength);
//   } else {
//     return value;
//   }
// };

// // const filterMenu = [{ value: 'ratings', label: 'Ratings' }];

// const filterRatings = [
//   { value: '', label: 'All' },
//   { value: 4, label: '4' },
//   { value: 3, label: '3' },
//   { value: 2, label: '2' },
//   { value: 1, label: '1' },
// ];

// const ClientReviewPage = () => {
//   const [data, setData] = useState([]);
//   const [filterData, setFilterData] = useState([]);
//   const [showLoader, setShowLoader] = useState(false);
//   const [limit, setLimit] = useState(10); // Initialize limit
//   const [page, setPage] = useState(1); // Initialize page
//   const [search, setSearch] = useState(''); // Initialize search
//   const [ratings, setRatings] = useState(''); // Initialize ratings

//   const { getClientReviews, activeBranchId, setShowMessage, branchData } = useMain();


//   useEffect(() => {
//     if (activeBranchId) {
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId]);

//   const getData = async () => {
//     const queryParams = `branchId=${activeBranchId}&ratings=${ratings}`;
//     try {
//       setShowLoader(true);
//       const res = await getClientReviews(queryParams);
//       if (res.statusCode === 200) {
//         setData(res.data || []);
//       } else {
//         console.error('Failed to fetch data:', res);
//         setShowMessage(`Failed to fetch data: ${res.message || 'Unknown error'}`);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setShowMessage(`Error fetching data: ${error.message}`);
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   // useEffect(() => {
//   //   if (activeBranchId) {
//   //     getData();
//   //   }
//   //   return () => {
//   //     setData([]);
//   //   };
//   // }, [activeBranchId]);
//   // console.log(data);
//   // const applyFilters = () => {
//   //   let filteredData = data.filter((item) => {
//   //     let isRatingMatch = true;
//   //     let isNameMatch = true;

//   //     if (ratings) {
//   //       isRatingMatch = item.avgRating >= ratings;
//   //     }

//   //     if (search) {
//   //       const clientName = item.clientName || '';
//   //       const clientNumber = item.clientNumber || '';
//   //       isNameMatch =
//   //         clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
//   //     }
//   //     return isRatingMatch && isNameMatch;
//   //   });

//   //   // Applying pagination
//   //   const startIndex = (page - 1) * limit;
//   //   const endIndex = startIndex + limit;
//   //   filteredData = filteredData.slice(startIndex, endIndex);
//   //   setFilterData(filteredData);
//   // };

//   const applyFilters = () => {
//     let filteredData = data.filter((item) => {
//       let isRatingMatch = true;
//       let isNameMatch = true;

//       // Check for ratings filter
//       if (ratings) {
//         isRatingMatch = item.avgRating >= ratings;
//       }

//       // Check for search filter
//       if (search) {
//         const clientName = item.clientName || '';
//         const clientNumber = item.clientNumber || '';
//         isNameMatch =
//           clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
//       }

//       return isRatingMatch && isNameMatch;
//     });

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData);
//   };

//   useEffect(() => {
//     applyFilters();
//   }, [data, limit, ratings, page, search]);

//   // const handleLimitChange = (event) => {
//   //   setLimit(event.target.value);
//   // };
//   const handleLimitChange = (event) => {
//     setLimit(event.target.value);
//     setPage(1); // Reset to page 1 when limit changes
//   };

//   const clearDataHandler = () => {
//     setRatings('');
//     setSearch('');
//     setPage(1); // Reset page to 1 on clear
//   };

//   return (
//     <PageContainer title="Branches" description="This contains all products">
//       <DashboardCard2 title="Client Review">
//         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.7rem' }}>
//           <div style={{ display: 'flex', flexDirection: 'column' }}>
//             <InputLabel>Show per page</InputLabel>
//             <Select
//               style={{
//                 borderRadius: '7px',
//                 border: '0.5px solid #6174DD',
//                 padding: '4px 12px',
//                 background: '#FFF',
//                 height: '40px',
//               }}
//               value={limit}
//               onChange={handleLimitChange}
//             >
//               {[10, 20, 50].map((value) => (
//                 <MenuItem key={value} value={value}>
//                   {value}
//                 </MenuItem>
//               ))}
//             </Select>
//           </div>

//           <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
//             <div style={{
//               marginRight: '2.5rem',
//               width: '106px'
//             }}>
//               <InputLabel>Ratings</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   width: '120%',
//                   display: 'flex',
//                 }}
//                 value={ratings}
//                 onChange={(e) => setRatings(e.target.value)}
//               >
//                 {/* Assuming filterRatings is defined somewhere */}
//                 {filterRatings.map((item, index) => (
//                   <MenuItem key={index} value={item.value}>
//                     {item.label}
//                     <img
//                       src={star_img}
//                       alt="star"
//                       style={{ width: '20px', height: '20px', marginBottom: '3px' }}
//                     />
//                     & above
//                   </MenuItem>
//                 ))}
//               </Select>
//             </div>

//             {/* Search */}
//             <div style={{ marginRight: '-1rem' }} onClick={() => document.getElementById('search-input').focus()}>
//               <InputLabel style={{ marginRight: '0.5rem' }}>Search</InputLabel>
//               <div className="search-input" style={{ display: 'flex', alignItems: 'center' }}>
//                 <img src={SearchImg} alt="" style={{ marginRight: '0.5rem' }} />
//                 <input
//                   id="search-input"
//                   type="text"
//                   placeholder="Search"
//                   value={search}
//                   onChange={(e) => setSearch(e.target.value)}
//                   style={{ borderRadius: '7px' }}
//                 />
//                 {search && (
//                   <IconButton
//                     onClick={() => {
//                       setSearch('');
//                       // setClear(false);
//                     }}
//                     style={{ padding: 1 }}
//                   >
//                     <ClearIcon style={{
//                       // color: '#ff4d4d',
//                       color: '#000',
//                       // color: '#A2A3AE',
//                       marginRight: '-0.2rem', marginTop: '0.1rem',
//                       fontSize: '1.1rem'
//                     }} />
//                   </IconButton>
//                 )}
//               </div>
//             </div>

//             {/* Apply and Clear Buttons */}
//             {/* <div style={{ marginTop: '1.8rem'}}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   height: '40px',
//                   width: '123%',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </div> */}
//           </div>
//         </div>

//         <table className="table-container mt-3">
//           <thead className="table-thead">
//             <tr>
//               {['Client Name', 'Contact', 'Avg Ratings'].map((h, index) => (
//                 <th key={index} style={{ width: '100%' }}>
//                   {h}
//                 </th>
//               ))}
//             </tr>
//           </thead>
//           <tbody className="table-tbody">
//             {filterData && filterData.length ? (
//               filterData.map((item) => (
//                 <tr key={item._id}>
//                   <td style={{ width: '100%' }}>{item.clientName}</td>
//                   <td style={{ width: '100%' }}>
//                     {typeof branchData?.isMasked !== 'boolean' || !item?.clientNumber
//                       ? 'loading...'
//                       : maskData(item?.clientNumber, branchData?.isMasked)}
//                   </td>
//                   <td style={{ width: '100%' }}>{item?.avgRating}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={3}>
//                   <Typography variant="body1">No reviews found</Typography>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>

//         {/* Pagination */}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             // count={filterData && filterData?.length < limit ? page : page + 1}
//             count={Math.ceil(data.length / limit)}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default ClientReviewPage;

import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { useMain } from '../hooks/useMain';
import star_img from '../../assets/images/star.png';
import { InputLabel, Select, MenuItem, Typography, Pagination, IconButton } from '@mui/material';
import SearchImg from '../../assets/search.svg';
import { Box } from '@mui/system';
import { ClearIcon } from '@mui/x-date-pickers-pro';

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const filterRatings = [
  { value: '', label: 'All' },
  { value: 4, label: '4' },
  { value: 3, label: '3' },
  { value: 2, label: '2' },
  { value: 1, label: '1' },
];

const ClientReviewPage = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [ratings, setRatings] = useState('');

  const { getClientReviews, activeBranchId, setShowMessage, branchData } = useMain();

  useEffect(() => {
    if (activeBranchId) {
      getData();
    }
    return () => {
      setData([]);
    };
  }, [activeBranchId]);

  const getData = async () => {
    const queryParams = `branchId=${activeBranchId}&ratings=${ratings}`;
    try {
      setShowLoader(true);
      const res = await getClientReviews(queryParams);
      if (res.statusCode === 200) {
        setData(res.data || []);
      } else {
        console.error('Failed to fetch data:', res);
        setShowMessage(`Failed to fetch data: ${res.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setShowMessage(`Error fetching data: ${error.message}`);
    } finally {
      setShowLoader(false);
    }
  };

  const applyFilters = () => {
    let filteredData = data.filter((item) => {
      let isRatingMatch = true;
      let isNameMatch = true;

      if (ratings) {
        isRatingMatch = item.avgRating >= ratings;
      }

      if (search) {
        const clientName = item.clientName || '';
        const clientNumber = item.clientNumber || '';
        isNameMatch =
          clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
      }

      return isRatingMatch && isNameMatch;
    });

    // Set the filtered data
    setFilterData(filteredData);
    
    // Reset to page 1 if the filtered data changes
    setPage(1);
  };

  useEffect(() => {
    applyFilters();
  }, [data, limit, ratings, search]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1); // Reset to page 1 when limit changes
  };

  const clearDataHandler = () => {
    setRatings('');
    setSearch('');
    setPage(1);
  };

  // Calculate the paginated data
  const startIndex = (page - 1) * limit;
  const paginatedData = filterData.slice(startIndex, startIndex + limit);

  return (
    <PageContainer title="Branches" description="This contains all products">
      <DashboardCard2 title="Client Review">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.7rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <InputLabel>Show per page</InputLabel>
            <Select
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
              }}
              value={limit}
              onChange={handleLimitChange}
            >
              {[10, 20, 50].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
            <div style={{ marginRight: '2.5rem', width: '106px' }}>
              <InputLabel>Ratings</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                  width: '120%',
                }}
                value={ratings}
                onChange={(e) => setRatings(e.target.value)}
              >
                {filterRatings.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                    <img
                      src={star_img}
                      alt="star"
                      style={{ width: '20px', height: '20px', marginBottom: '3px' }}
                    />
                    & above
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div style={{ marginRight: '-1rem' }} onClick={() => document.getElementById('search-input').focus()}>
              <InputLabel style={{ marginRight: '0.5rem' }}>Search</InputLabel>
              <div className="search-input" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={SearchImg} alt="" style={{ marginRight: '0.5rem' }} />
                <input
                  id="search-input"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ borderRadius: '7px' }}
                />
                {search && (
                  <IconButton
                    onClick={() => setSearch('')}
                    style={{ padding: 1 }}
                  >
                    <ClearIcon style={{
                      color: '#000',
                      marginRight: '-0.2rem', marginTop: '0.1rem',
                      fontSize: '1.1rem'
                    }} />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </div>

        <table className="table-container mt-3">
          <thead className="table-thead">
            <tr>
              {['Client Name', 'Contact', 'Avg Ratings'].map((h, index) => (
                <th key={index} style={{ width: '100%' }}>
                  {h}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-tbody">
            {paginatedData.length ? (
              paginatedData.map((item) => (
                <tr key={item._id}>
                  <td style={{ width: '100%' }}>{item.clientName}</td>
                  <td style={{ width: '100%' }}>
                    {typeof branchData?.isMasked !== 'boolean' || !item?.clientNumber
                      ? 'loading...'
                      : maskData(item?.clientNumber, branchData?.isMasked)}
                  </td>
                  <td style={{ width: '100%' }}>{item?.avgRating}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <Typography variant="body1">No reviews found</Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination */}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={Math.ceil(filterData.length / limit) || 1} // Use filterData length for pagination
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer>
  );
};

export default ClientReviewPage;
