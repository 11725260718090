import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  CircularProgress
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReqField } from 'src/components/CustomComponents/CustomLoader';
import TableCells from 'src/components/Tables/TableCells';
import TextInputs from 'src/components/inputs/TextInputs';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import SelectInputs from 'src/components/inputs/SelectInputs';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

export const initialTypeOfExpense = [
  { label: 'Salary' },
  { label: 'Staff Advance' },
  { label: 'Others' },
];
const paymentmode = ['Cash', 'Credit/Debit Card', 'Paytm', 'PhonePe', 'Gpay'];

const Expenses = ({ data, setData, getExpenses }) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [typeOfExpenseData, setTypeOfExpenseData] = useState(initialTypeOfExpense);
  const [staff, setStaff] = useState([]);

  const [openDialogue, setOpenDialogue] = useState(false);
  const [updateExpenseData, setUpdateExpenseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete confirmation dialog

  const {
    updateExpense,
    setShowMessage,
    deleteExpense,
    activeBranchId,
    fetchAllExpenseType,
    getAllStaff,
  } = useMain();

  const handleUpdate = (item) => {
    setSelectedItemId(item._id);
    const {
      date,
      typeOfExpense,
      amountPaid,
      modeOfPayment,
      recipientName,
      description,
      others,
      staffId,
    } = item;
    setUpdateExpenseData((pre) => {
      return {
        ...pre,
        date,
        typeOfExpense,
        others,
        staffId,
        amountPaid,
        modeOfPayment,
        recipientName,
        description,
      };
    });
    setOpenDialogue(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateExpenseData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const hadleupdateExpenseData = async () => {
    try {
      setLoading(true);
      const res = await updateExpense(
        selectedItemId,
        updateExpenseData,
        `?branchId=${activeBranchId}`,
      );
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Expense has been updated',
          messageType: 'success',
        });
        setData([]);
        getExpenses();
        getAllTypeOfExpense();
      } else {
        setShowMessage({
          message: res.message || 'Expense has not been updated',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(false);
    }
  };

  // const handleDeleteField = async (id) => {
  //   try {
  //     const res = await deleteExpense(id, `?branchId=${activeBranchId}`);
  //     if (res?.statusCode === 200) {
  //       setShowMessage({
  //         message: res.message || 'Expense has been deleted',
  //         messageType: 'success',
  //       });
  //       setData(data?.filter((data) => data?._id !== id));
  //     } else {
  //       setShowMessage({
  //         message: res.message || 'Expense has not been deleted',
  //         messageType: 'error',
  //       });
  //     }
  //   } catch (error) {
  //     setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
  //   } finally {
  //   }
  // };
  const handleDeleteField = (id) => {
    setSelectedItemId(id);
    setOpenDeleteDialog(true);
  };

  // const confirmDelete = async () => {
  //   try {
  //     setLoading(true); // Set loading state to true
  //     const res = await deleteExpense(selectedItemId, `?branchId=${activeBranchId}`);
  //     if (res?.statusCode === 200) {
  //       // Show success message
  //       setShowMessage({ message: 'Appointment deleted successfully', messageType: 'success' });
  //       // Fetch updated data after deletion
  //       await getAllTypeOfExpense(); // Ensure you await this if you want to handle the loader properly
  //     } else {
  //       setShowMessage({
  //         message: res?.message || 'Error occurs in delete appointment',
  //         messageType: 'error',
  //       });
  //     }
  //   } catch (error) {
  //     setShowMessage({
  //       message: error?.message || 'Error occurs in delete appointment',
  //       messageType: 'error',
  //     });
  //   } finally {
  //     setOpenDeleteDialog(false);
  //     setLoading(false); // Reset loading state
  //   }
  // };/
  const confirmDelete = async () => {
    try {
      setLoading(true); // Set loading state to true
      const res = await deleteExpense(selectedItemId, `?branchId=${activeBranchId}`);
      if (res?.statusCode === 200) {
        // Show success message
        setShowMessage({ message: 'Expense deleted successfully', messageType: 'success' });
        // Fetch updated data after deletion
        await getExpenses(); // Fetch updated expenses data
      } else {
        setShowMessage({
          message: res?.message || 'Error occurred while deleting expense',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error?.message || 'Error occurred while deleting expense',
        messageType: 'error',
      });
    } finally {
      setOpenDeleteDialog(false);
      setLoading(false); // Reset loading state
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedItemId('');
  };


  const getAllTypeOfExpense = async () => {
    try {
      const res = await fetchAllExpenseType(activeBranchId);
      if (res.statusCode === 200) {
        setTypeOfExpenseData((pre) => [
          ...[{ label: 'Salary' }, { label: 'Staff Advance' }],
          ...res?.data.map((type) => ({ label: type })),
          { label: 'Others' },
        ]);
      }
    } catch (error) { }
  };

  // const getAllStaffData = async () => {
  //   try {
  //     const res = await getAllStaff(activeBranchId);
  //     setStaff(res?.data || []);
  //   } catch (error) {}
  // };
  useEffect(() => {
    if (activeBranchId) {
      // getAllStaffData();
      getAllTypeOfExpense();
    }
    return () => {
      setTypeOfExpenseData(initialTypeOfExpense);
      setStaff({});
    };
  }, [activeBranchId]);

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            {[
              'Date',
              'Expense Type',
              'Amount Paid',
              'Payment Mode',
              'Receipt Name',
              'Description',
              'Actions',
            ].map((h, index) => {
              return (
                <th key={index} style={{ width: '100%' }}>
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => {
            const originalDate = item?.date;

            // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
            let formattedExpenseDate = '';
            if (originalDate) {
              const [year, month, day] = originalDate.split('-');
              formattedExpenseDate = `${day}-${month}-${year}`;
            }
            return (
              <tr key={item._id}>
                <td style={{ width: '100%' }}>{formattedExpenseDate}</td>
                <td style={{ width: '100%' }}>{item?.typeOfExpense}</td>
                <td style={{ width: '100%' }}>{item?.amountPaid}</td>
                <td style={{ width: '100%' }}>{item?.modeOfPayment}</td>
                <td style={{ width: '100%' }}>{item?.recipientName}</td>
                <td style={{ width: '100%' }}>{item?.description}</td>

                {/* <td style={{ width: '100%' }}>
                  <TableCells
                    type="button"
                    handleDeleteField={handleDeleteField}
                    handleUpdate={() => handleUpdate(item)}
                    // handleDetails={()=>{}}
                    field={item?._id}
                  />
                </td> */}

                <td style={{
                  width: '100%',
                  // marginLeft: '-1rem',
                }}>
                  <IconButton onClick={() => handleUpdate(item)} color="secondary" title="Update" style={{ margin: '-2px' }}>
                    <FaRegEdit style={{ color: '#696969', fontSize: '1rem' }} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteField(item._id)} color="error" title="Delete" style={{ margin: '-6px' }}>
                    <MdDelete color='#FF4D4D' style={{ fontSize: '1.2rem', marginTop: '1px' }} />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogContent>
          Are you sure you want to delete this Expense?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>


      {/* ---------------------- Expense Details Dialog ---------------- */}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>Expense Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update expense details.</DialogContentText>
          <Box style={{ marginTop: '10px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Date'}
                  name={'date'}
                  value={updateExpenseData?.date}
                  handleChange={handleChange}
                  type={'date'}
                  max={new Date().toISOString().split('T')[0]}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectInputs
                  name={'typeOfExpense'}
                  value={updateExpenseData?.typeOfExpense}
                  title={'Type of Expense'}
                  handleChange={handleChange}
                  options={typeOfExpenseData}
                  optionValue={'label'}
                  optionTitle={'label'}
                  required={false}
                />
              </Grid>
              {updateExpenseData.typeOfExpense === 'Others' ? (
                <Grid item xs={12} md={4}>
                  <TextInputs
                    title={'Others'}
                    name={'others'}
                    value={updateExpenseData?.others}
                    handleChange={handleChange}
                    type={'text'}
                    required={false}
                  />
                </Grid>
              ) : updateExpenseData.typeOfExpense === 'Staff Advance' ? (
                <Grid item xs={12} md={4}>
                  <SelectInputs
                    name={'staffId'}
                    value={updateExpenseData?.staffId}
                    title={'Staff'}
                    handleChange={handleChange}
                    options={staff}
                    optionValue={'_id'}
                    optionTitle={'name'}
                    required={false}
                  />
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Amount Paid'}
                  name={'amountPaid'}
                  value={updateExpenseData?.amountPaid}
                  placeholder={'0'}
                  handleChange={handleChange}
                  type={'number'}
                  min={'0'}
                  required={false}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <div style={{ marginBottom: '6px', fontWeight: 'bold', color: 'black' }}>
                  Payment Mode <ReqField />
                </div>
                <Select
                  name="modeOfPayment"
                  value={updateExpenseData?.modeOfPayment}
                  onChange={handleChange}
                  fullWidth
                >
                  {paymentmode?.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Receipt Name'}
                  name={'recipientName'}
                  value={updateExpenseData?.recipientName}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Description'}
                  name={'description'}
                  value={updateExpenseData?.description}
                  handleChange={handleChange}
                  type={'text'}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={hadleupdateExpenseData}
                >
                  update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Expenses;
