import React, { useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { ToggleButtonGroup, ToggleButton, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ServiceMembership from './ServiceMembership';
import DiscountMembership from './DiscountMembership';

const AddMembership = () => {
  const [membershipType, setMembershipType] = useState('serviceType');
  const navigate = useNavigate();

  const handleMembershipTypeChange = (event, newType) => {
    if (newType !== null) {
      setMembershipType(newType);
    }
  };

  return (
    <PageContainer title="Create Membership" description="This is add Membership">
      <DashboardCard title="Create Membership">
        {/* <ToggleButtonGroup
          value={membershipType}
          exclusive
          onChange={handleMembershipTypeChange}
          aria-label="membership type"
          style={{
            borderRadius: '30px',
            height: '40px',
            backgroundColor: '#f0f0f0',
            width: '47%',
            border: '1px solid #cfcece',
          }}
        >
          <ToggleButton value="serviceType" aria-label="Service Membership"
            sx={{
              flex: 1,
              color: '#000',
              backgroundColor: '#fafafa',
              borderRadius: '30px',
              border: 'none',
              '&.Mui-selected': {
                backgroundColor: '#6174DD',
                color: '#fff',
                border:'none',
                '&:hover': {
                  backgroundColor: '#6174DD',
                  // color: '#fff',
                }
              },
              '&:not(.Mui-selected)': {
                '&:hover': {
                  backgroundColor: '#d0d0d0',
                },
              },
            }}>
            Service Membership
          </ToggleButton>
          <div style={{ height: '39px', width: '2px', backgroundColor: '#cfcece' }} />
          <ToggleButton value="discountType" aria-label="Discount Membership"
            sx={{
              flex: 1,
              color: '#000',
              backgroundColor: '#fafafa',
              borderRadius: '30px',
              '&.Mui-selected': {
                backgroundColor: '#6174DD',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#6174DD',
                  color: '#fff',
                }
              },
              '&:not(.Mui-selected)': {
                '&:hover': {
                  backgroundColor: '#d0d0d0',
                },
              },
            }}>
            Discount Membership
          </ToggleButton>
          <div style={{ height: '39px', width: '2px', backgroundColor: '#cfcece' }} />
          <ToggleButton value="packages" aria-label="Packages" onClick={() => navigate('/PackagePage')}
            sx={{
              flex: 1,
              color: '#000',
              backgroundColor: '#fafafa',
              borderRadius: '30px',
              '&.Mui-selected': {
                backgroundColor: '#6174DD',
                color: '#fff',
              },
              '&:not(.Mui-selected)': {
                '&:hover': {
                  backgroundColor: '#d0d0d0',
                },
              },
            }}>
            Packages
          </ToggleButton>
        </ToggleButtonGroup> */}

        <ToggleButtonGroup
          value={membershipType}
          exclusive
          onChange={handleMembershipTypeChange}
          aria-label="membership type"
          style={{
            borderRadius: '30px',
            height: '40px',
            backgroundColor: '#f0f0f0',
            width: '47%',
            border: '1px solid #cfcece',
          }}
        >
          <ToggleButton
            value="serviceType"
            aria-label="Service Membership"
            sx={{
              flex: 1,
              color: '#000',
              backgroundColor: '#fafafa',
              borderRadius: '30px',
              border: 'none',
              '&.Mui-selected': {
                backgroundColor: '#6174DD',
                color: '#fff',
                border: 'none',
                '&:hover': {
                  backgroundColor: '#6174DD',
                },
                '& + div': { // Hide right border of the selected button
                  display: 'none',
                },
              },
              '&:not(.Mui-selected)': {
                '&:hover': {
                  backgroundColor: '#d0d0d0',
                },
              },
            }}
          >
            Service Membership
          </ToggleButton>

          {/* <div style={{ height: '39px', width: '2px', backgroundColor: '#cfcece' }} /> */}
          <div style={{ height: '39px', backgroundColor: '#cfcece' }} />

          <ToggleButton
            value="discountType"
            aria-label="Discount Membership"
            sx={{
              flex: 1,
              color: '#000',
              backgroundColor: '#fafafa',
              borderRadius: '30px',
              border: 'none',
              '&.Mui-selected': {
                backgroundColor: '#6174DD',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#6174DD',
                },
                '&:(:first-of-type) + div': { // Hide left border of the selected button
                  display: 'none',
                },
                '&:not(:first-of-type) + div': { // Hide right border for selected button
                  display: 'none',
                },
              },
              '&:not(.Mui-selected)': {
                '&:hover': {
                  backgroundColor: '#d0d0d0',
                },
              },
            }}
          >
            Discount Membership
          </ToggleButton>

          <div style={{ height: '39px', width: '2px', backgroundColor: '#cfcece' }} />

          <ToggleButton
            value="packages"
            aria-label="Packages"
            onClick={() => navigate('/PackagePage')}
            sx={{
              flex: 1,
              color: '#000',
              backgroundColor: '#fafafa',
              borderRadius: '30px',
              border: 'none',
              '&.Mui-selected': {
                backgroundColor: '#6174DD',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#6174DD',
                },
                '&:not(:last-of-type) + div': { // Hide right border for selected but not last
                  display: 'none',
                },
              },
              '&:not(.Mui-selected)': {
                '&:hover': {
                  backgroundColor: '#d0d0d0',
                },
              },
            }}
          >
            Packages
          </ToggleButton>
        </ToggleButtonGroup>


        {membershipType === 'serviceType' ? (
          <ServiceMembership />
        ) : membershipType === 'discountType' ? (
          <DiscountMembership />
        ) : null}
      </DashboardCard>
    </PageContainer>
  );
};

export default AddMembership;
