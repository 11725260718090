import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Box, Button, Grid, IconButton, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
import Coupons from './Coupons';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import SearchImg from '../../assets/search.svg';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { ClearIcon } from '@mui/x-date-pickers-pro';

const AllCoupons = () => {
  const { fetchAllCoupon, activeBranchId } = useMain();

  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const [filterData, setFilterData] = useState([]);
  console.log(filterData);

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    if (clear) {
      getData('clear');
      setClear(false);
    }
  };

  const onApplyHandler = () => {
    if (search) {
      getData();
      setClear(true);
    }
  };

  const getData = async () => {
    let queryParams = `?page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`;

    try {
      setShowLoader(true);
      const res = await fetchAllCoupon(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      getData();
    }
    return () => {
      setData([]);
    };
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters();
  }, [data, limit, page, search]);

  const applyFilters = () => {
    console.log(data);
    console.log(search);
    let filteredData = data.filter(item => {
      let isNameMatch = true;
      if (search) {
        isNameMatch = item?.couponCode?.toLowerCase().includes(search.toLowerCase());
      }
      return isNameMatch;
    });

    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  return (
    <PageContainer title="Manage Coupons" description="This contains all coupons">
      <DashboardCard2 title="Manage Coupons">
        <div style={{ display: 'grid', gridTemplateColumns: '2fr auto' }}>
          <div style={{ marginBottom: '1.7rem' }}>
            <InputLabel>Show per page</InputLabel>
            <Select
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
                width: '10%',
              }}
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center', gap: '1rem', width: '76%' }}>
            <div style={{ marginBottom: '1.6rem', marginRight: '-1rem', cursor: 'pointer' }} onClick={() => document.getElementById('search-input').focus()}>
              <InputLabel style={{ cursor: 'pointer' }}>Search</InputLabel>
              <div className="search-input" style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={SearchImg}
                  alt="Search Icon"
                  style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the click from bubbling up to the outer div
                    document.getElementById('search-input').focus();
                  }}
                />
                <input
                  id="search-input" 
                  type="text"
                  // placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ marginLeft: '0.5rem', flex: 1, borderRadius: '7px' }} // Added flex: 1 for better input width
                />
                {search && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents triggering the focus on the input
                      setSearch('');
                      setClear(false);
                    }}
                    style={{ padding: 1 }}
                  >
                    <ClearIcon style={{
                      color: '#000',
                      marginRight: '-0.2rem',
                      marginTop: '0.1rem',
                      fontSize: '1.1rem'
                    }} />
                  </IconButton>
                )}
              </div>
            </div>

            {/* <div style={{ marginRight: '1rem' }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  // width: '100%',
                  width: '57%',
                  marginLeft: '5rem',
                  background: '#ff4d4d',
                  '&:hover': {
                    background: '#ff6666',
                  },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </div> */}
          </div>
        </div>

        {showLoader ? (
          <ShowLoader />
        ) : filterData && filterData?.length > 0 ? (
          <>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Coupons data={filterData} setData={setData} getCoupons={getData} />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          'No data found'
        )}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={filterData && filterData?.length < limit ? page : page + 1}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllCoupons;
