import { baseUrl, headers, jwtHeaders } from '../common/data';

export const fetchAllExpenses = async (branchId, queryParams) => {

  const response = await fetch(`${baseUrl}/api/expense/findByBranch/${branchId}${queryParams ? queryParams : ''}`,
    {
      headers: jwtHeaders()
    }
  );
  const resData = await response.json();

  return resData;

};


export const createExpense = async (data) => {

  const response = await fetch(`${baseUrl}/api/expense/create`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;

};

export const updateExpense = async (id, data, branchId) => {

  const response = await fetch(`${baseUrl}/api/expense/update/${id}${branchId ? branchId : ''}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: jwtHeaders(),
  })
  const resData = await response?.json();

  return resData;

}

// export const deleteExpense=async(id,branchId)=>{
//   const response=await fetch(`${baseUrl}/api/expense/delete/${id}${branchId ? branchId : ''}`,{
//    method:"DELETE",
//    headers:jwtHeaders()
//   })
//   const resData = await response?.json();
//   return resData;
// }

export const deleteExpense = async (id, branchId) => {
  const response = await fetch(`${baseUrl}/api/expense/delete/${branchId}`, {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json',
      ...jwtHeaders(),
    },
    body: JSON.stringify({
      ids: [id],
      status: false,
    }),
  },
  );
  const resData = await response?.json();
  return resData;
}

export const fetchExpenseAnalytics = async (branchId, queryParams) => {
  const response = await fetch(`${baseUrl}/api/expense/combinedexpenseapi/${branchId}${queryParams ? queryParams : ''}`, {
    method: 'GET',
    headers: jwtHeaders()
  })
  const resData = await response.json()
  return resData
}

export const fetchAllExpenseType = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/expense/allexpensetype/${branchId}`, {
    method: 'GET',
    headers: jwtHeaders()
  })
  const resData = await response.json()
  return resData
}