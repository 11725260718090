//correct pagination 
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { MenuItem, Select, Box, Button, Grid, InputLabel, Pagination, IconButton } from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import SearchImg from '../../assets/search.svg';
import Expenses from './Expenses';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import BigFilter from 'src/components/inputs/BigFilter';
import ClearIcon from '@mui/icons-material/Close';

const expensestype = ['Salary', 'Others'];

const filterModeOfPayment = [
    { label: 'Cash', value: 'Cash' },
    { label: 'Credit/Debit Card', value: 'Credit/Debit Card' },
    { label: 'Paytm', value: 'Paytm' },
    { label: 'PhonePe', value: 'PhonePe' },
    { label: 'Gpay', value: 'Gpay' },
];

const AllExpenses = () => {
    const { fetchAllExpenses, activeBranchId, fetchAllExpenseType } = useMain();

    const [data, setData] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [search, setSearch] = useState('');
    const [filterSelection, setFilterSelection] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [clear, setClear] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const clearDataHandler = () => {
        setLimit(10);
        setPage(1);
        setSearch('');
        setFilterSelection([]); // Clear the filters
        setFilteredData(data); // Reset the filtered data to the original data
    };

    const getData = async () => {
        try {
            setShowLoader(true);
            const res = await fetchAllExpenses(activeBranchId);
            if (res.statusCode === 200) {
                setData(res?.data || []);
                setFilteredData(res?.data || []); // Initialize filtered data
            }
        } catch (error) {
            // Handle error if necessary
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        if (activeBranchId) {
            getData();
        }
        return () => {
            setData([]);
            setFilteredData([]);
        };
    }, [activeBranchId]);

    const handleLimitChange = (e) => {
        setLimit(e.target.value);
        setPage(1);
    };

    const getExpensesTypeData = async () => {
        try {
            const res = await fetchAllExpenseType(activeBranchId);
            if (res.statusCode === 200) {
                setTypeData(res.data || []);
            }
        } catch (error) {
            setTypeData([]);
        }
    };

    useEffect(() => {
        if (activeBranchId) {
            getExpensesTypeData();
        }
        return () => {
            setTypeData([]);
        };
    }, [activeBranchId]);

    const applyFilters = () => {
        let filteredData = data.filter(item => {
            let isModeOfPaymentMatch = filterSelection.some(filter => filterModeOfPayment.find(option => option.value === filter)?.value === item.modeOfPayment);
            let isTypeOfExpenseMatch = filterSelection.some(filter => typeData.includes(filter) && filter === item?.typeOfExpense);
            let isFilterMatch =
                (filterSelection.length === 0) ||
                (isModeOfPaymentMatch && isTypeOfExpenseMatch) ||
                (isModeOfPaymentMatch && !filterSelection.some(filter => typeData.includes(filter))) ||
                (isTypeOfExpenseMatch && !filterSelection.some(filter => filterModeOfPayment.find(option => option.value === filter)));
            let isNameMatch = search
                ? item?.recipientName?.toLowerCase().includes(search.toLowerCase()) ||
                item?.typeOfExpense?.toLowerCase().includes(search.toLowerCase())
                : true;

            return isFilterMatch && isNameMatch;
        });

        setFilteredData(filteredData);
    };

    useEffect(() => {
        applyFilters();
    }, [search, filterSelection, limit, page, data]);

    const paginatedData = filteredData.slice((page - 1) * limit, page * limit);

    const groupedOptions = [
        {
            title: 'Mode of Payment',
            items: filterModeOfPayment.map(item => ({ label: item.label, value: item.value }))
        },
        {
            title: 'Type of Expense',
            items: typeData.map(type => ({ label: type, value: type }))
        }
    ];

    return (
        <PageContainer title="All Expenses" description="This contains all expenses">
            <DashboardCard2 title="All Expenses">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.7rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <InputLabel style={{ marginRight: '0.5rem' }}>Show per page</InputLabel>
                        <Select
                            style={{
                                borderRadius: '7px',
                                border: '0.5px solid #6174DD',
                                padding: '4px 12px',
                                background: '#FFF',
                                height: '40px',
                                width: '100px'
                            }}
                            value={limit}
                            onChange={handleLimitChange}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <div style={{ marginRight: '1rem' }}> */}
                        <div style={{ marginRight: '2rem', width: '272px' }}>
                            <BigFilter
                                options={groupedOptions}
                                value={filterSelection}
                                onChange={(newValues) => setFilterSelection(newValues)}
                                placeholder="Filters"
                                style={{ flex: 1, marginRight: '-1rem', marginBottom: '1.3rem' }} // Adjusted for spacing
                            />
                        </div>
                        <div style={{ marginRight: '0rem' }} onClick={() => document.getElementById('search-input').focus()}>
                            <InputLabel style={{ marginRight: '0.5rem' }}>Search</InputLabel>
                            <div className="search-input" style={{ display: 'flex', alignItems: 'center',cursor:'pointer' }}>
                                <img src={SearchImg} alt="" style={{ marginRight: '0.5rem' }} />
                                <input
                                    id="search-input" // Add an ID to the input field
                                    type="text"
                                    placeholder=""
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{ width: '100%' }} // Ensure full width
                                />
                                {search && (
                                    <IconButton
                                        onClick={() => {
                                            setSearch('');
                                            setClear(false);
                                        }}
                                        style={{ padding: 1 }}
                                    >
                                        <ClearIcon style={{
                                            color: '#000',
                                            marginRight: '-0.2rem',
                                            marginTop: '0.1rem',
                                            fontSize: '1.1rem'
                                        }} />
                                    </IconButton>
                                )}
                            </div>
                        </div>


                        {/* <div style={{ marginTop: '1.8rem' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={clearDataHandler}
                                style={{ backgroundColor: '#ff4d4d', color: 'white', width: '6rem', padding: '0.5rem 1rem' }}
                            >
                                Clear
                            </Button>
                        </div> */}
                    </div>
                </div>

                {showLoader ? (
                    <ShowLoader />
                ) : filteredData && filteredData.length > 0 ? (
                    <>
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12}>
                                    <Expenses data={paginatedData} setData={setData} getExpenses={getData} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            marginTop="20px"
                        >
                            <Pagination
                                style={{ justifyContent: "end" }}
                                count={Math.ceil(filteredData.length / limit)}
                                page={page}
                                onChange={(event, value) => setPage(value)}
                                color="primary"
                                shape="circle"
                            />
                        </Box>
                    </>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="start"
                        height="200px"
                        marginTop="20px"
                        flexDirection="column"
                    >
                        <p>No expenses found.</p>
                    </Box>
                )}
            </DashboardCard2 >
        </PageContainer >
    );
};

export default AllExpenses;
