import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Select,
  MenuItem,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  CircularProgress,
} from '@mui/material';
// import EditProduct from './EditProduct';
import { useMain } from '../hooks/useMain';
// import { useNavigate } from 'react-router';
import TableCells from 'src/components/Tables/TableCells';
// import { Search } from '@mui/icons-material';
import TextInputs from 'src/components/inputs/TextInputs';
import { ReqField } from 'src/components/CustomComponents/CustomLoader';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import CheckboxInputs from 'src/components/inputs/CheckboxInputs';
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

const initialUpdatedData = {
  productName: '',
  mrp: '',
  volume: '',
  unit: '',
  productCount: '',
  sellPrice: '',
  // barcode: '',
  rewardPoints: '',
  staffIncentive: '',
  incentiveType: '%',
};
const incentiveTypes = ['%', 'INR'];

const Products = ({ data, setData, getData, checkedProducts, setCheckedProducts }) => {
  const [updateProductData, setUpdateProductData] = useState(initialUpdatedData);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectItemId, setSelectItemId] = useState('');
  const [openDialogue, setOpenDialogue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateCount, setUpdateCount] = useState({});
  const [loadingProductId, setLoadingProductId] = useState('');

  const { deleteProduct, updateProduct, addProductCount, setShowMessage, activeBranchId } = useMain();

  const handleUpdate = (item) => {
    setSelectItemId(item._id);
    const { branchDetails, createdAt, updatedAt, __v, _id, ...rest } = item;
    setUpdateProductData((pre) => ({ ...pre, ...rest }));
    setOpenDialogue(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const hadleupdateProductData = async () => {
    try {
      setLoading(true);
      const { availability, ...updateDataWithoutAvailability } = updateProductData;

      const res = await updateProduct(
        selectItemId,
        updateDataWithoutAvailability,
        `?branchId=${activeBranchId}`
      );
      if (res?.statusCode === 200) {
        setShowMessage({ message: res.message || 'Product details updated successfully', messageType: 'success' });
        setData([]);
        getData();
      } else {
        setShowMessage({ message: res.message || 'Product details not updated', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(!openDialogue);
    }
  };

  // const handleDeleteField = async (id) => {
  //   try {
  //     const res = await deleteProduct(id, `?branchId=${activeBranchId}`);
  //     if (res?.statusCode === 200) {
  //       setData(data?.filter((data) => data?._id !== id));
  //       setShowMessage({
  //         message: res.message || 'Product deleted successfully',
  //         messageType: 'success',
  //       });
  //     } else {
  //       setShowMessage({ message: res.message || 'Product not deleted ', messageType: 'error' });
  //     }
  //   } catch (error) {
  //     setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
  //   }
  // };

  const handleDeleteField = (id) => {
    setDeleteId(id); // Set the ID to be deleted
    setOpenDeleteDialog(true); // Open confirmation dialog
  }


  const confirmDelete = async () => {
    setDeleteLoading(true); // Start loading
    try {
      const res = await deleteProduct(deleteId, `?branchId=${activeBranchId}`); // Make sure to pass the correct parameters
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Product deleted successfully',
          messageType: 'success',
        });
        setData(prevData => prevData.filter((d) => d?._id !== deleteId)); // Update state
        await getData(); // Refresh the data after deletion
      } else {
        setShowMessage({
          message: res.message || 'Product not deleted',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Something went wrong',
        messageType: 'error',
      });
    } finally {
      setOpenDeleteDialog(false); // Close dialog after operation
      setDeleteId(null); // Reset delete ID
      setDeleteLoading(false); // Stop loading
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setCheckedProducts([]);
    } else {
      setCheckedProducts(data);
    }
    setIsAllChecked(!isAllChecked);
  };

  const handleSignleCheckboxChange = (productId) => {
    if (checkedProducts.length && checkedProducts.some((check) => check._id === productId)) {
      setCheckedProducts((prev) => prev.filter((check) => check._id !== productId));
    } else {
      const checked = data.filter((item) => item._id === productId);
      setCheckedProducts((prev) => [...prev, ...checked]); // Create a new array
    }
  };

  useEffect(() => {
    setIsAllChecked((data?.length && checkedProducts?.length === data?.length) || false);
  }, [checkedProducts?.length, data?.length]);

  const handleIncentiveTypeChange = (event, newIncentiveType) => {
    if (newIncentiveType !== null) {
      setUpdateProductData({ ...updateProductData, incentiveType: newIncentiveType });
    }
  };
  // console.log(updateProductData, "sssssssssssssss");

  const handleCountChange = (item, e) => {
    const { value } = e.target;
    setUpdateCount((prevCounts) => ({
      ...prevCounts,
      [item._id]: value, // Set the count for the specific product
    }));
  };

  const handleCountButton = async (item) => {
    const updatedCount = updateCount[item._id];
    if (!updatedCount) {
      setShowMessage({ message: 'Please enter a valid count', messageType: 'error' });
      return;
    }
    try {
      setLoadingProductId(item._id);
      setLoading(true);
      const res = await addProductCount(
        item._id, // productId
        { productCount: updatedCount }, // Updated count from the state
        activeBranchId // branchId
      );

      if (res?.statusCode === 200) {
        setShowMessage({ message: res.message || 'Product count updated successfully', messageType: 'success' });
        setData([]); // Clear current data
        getData(); // Refresh or fetch the latest data
      } else {
        setShowMessage({ message: res.message || 'Product count not updated', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setLoadingProductId('');
    }
  };

  console.log(data, 'dataaa')

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            <th style={{ width: '15%' }}>
              <CheckboxInputs
                type={'Heading'}
                isAllChecked={isAllChecked}
                handleAllCheckboxChange={handleAllCheckboxChange}
              />
            </th>
            {[
              'Product Name',
              'Volume',
              'Count',
              'Sell (₹)',
              'MRP',
              // 'Barcode',
              'Reward',
              'Staff Incentive',
              // 'Details',
              'Actions',
              'Increase Count'
            ].map((h, index) => {
              return (
                <th key={index} style={{ width: '100%' }}>
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => {
            return (
              <tr key={item._id}>
                <td style={{ width: '20%' }}>
                  <CheckboxInputs
                    type={'content'}
                    handleSignleCheckboxChange={handleSignleCheckboxChange}
                    checkedItems={checkedProducts}
                    fieldId={item?._id}
                  />
                </td>
                <td style={{ width: '100%' }}>{item?.productName}</td>
                <td style={{ width: '100%' }}>{`${item?.volume}   ${item?.unit}`}</td>
                <td style={{ width: '100%' }}>{item?.productCount}</td>
                <td style={{ width: '100%' }}>{item?.sellPrice}</td>
                <td style={{ width: '100%' }}>{item?.mrp}</td>
                {/* <td style={{ width: '100%' }}>{item?.barCode}</td> */}
                <td style={{ width: '100%' }}>{item?.rewardPoints}</td>
                <td style={{ width: '70%' }}>{`${item?.staffIncentive ? `${item?.staffIncentive} ${item?.incentiveType}` : ''
                  }`}</td>

                {/* <td style={{ width: '100%' }}>
                  <TableCells
                    type="button"
                    handleDeleteField={handleDeleteField}
                    handleUpdate={() => handleUpdate(item)}
                    // handleDetails={()=>{}}
                    field={item?._id}
                  />
                </td> */}
                <td style={{
                  width: '100%',
                }}>
                  <IconButton onClick={() => handleUpdate(item)} color="secondary" title="Update" style={{ marginRight: '24px' }}>
                    <FaRegEdit style={{ color: '#696969', fontSize: '1rem' }} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteField(item._id)} color="error" title="Delete" style={{ marginLeft: '-33px' }}>
                    <MdDelete color='#FF4D4D' style={{ fontSize: '1.2rem', marginTop: '1px' }} />
                  </IconButton>
                </td>
                <td style={{ width: '100%', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <input
                    type="number"
                    value={updateCount[item._id] || ''} // Get the count value from state
                    // placeholder="INC"
                    placeholder="QTY"
                    onChange={(e) => handleCountChange(item, e)}
                    style={{
                      padding: '5px',
                      marginRight: '10px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      width: '100%',
                      backgroundColor: item?.productCount < 5 ? '#D3D3D3' : 'white',
                    }}
                  />
                  <button
                    onClick={() => handleCountButton(item)}
                    style={{
                      backgroundColor: item?.productCount < 5 ? '#D3D3D3' : '#4CAF50',
                      color: item?.productCount < 5 ? 'black' : 'white',
                      padding: '6px 12px',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                  >
                     {loading && loadingProductId === item._id ? (
                    <CircularProgress size={20} color="inherit" /> // Show loader while loading
                  ) : (
                    'Add'
                  )}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>


      {/* ---------------------- Product details dailog ---------------- */}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>Product Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update Product details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3} justify="center">
              {/* <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Vendor Name'}
                  name={'vendorName'}
                  value={updateProductData?.vendorName}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid> */}
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Product Name'}
                  name={'productName'}
                  value={updateProductData?.productName}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={4} container>
                <Grid item xs={12} container spacing={3} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextInputs
                      title={'MRP'}
                      name={'mrp'}
                      value={updateProductData?.mrp}
                      handleChange={handleChange}
                      type={'number'}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInputs
                      title={'Volume'}
                      name={'volume'}
                      value={updateProductData?.volume}
                      handleChange={handleChange}
                      type={'number'}
                      min={1}
                      required={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <div style={{ marginBottom: '5px', fontWeight: 'bold', color: 'black' }}>Incentive Type</div>
                <Select
                  name="unit"
                  value={updateProductData?.unit}
                  onChange={handleChange}
                  fullWidth
                >
                  {['ml', 'l', 'gm', 'kg']?.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextInputs
                  title={'Product Count'}
                  name={'productCount'}
                  value={updateProductData?.productCount}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextInputs
                  title={'Sell Price'}
                  name={'sellPrice'}
                  value={updateProductData?.sellPrice}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <TextInputs
                  title={'Reward Points'}
                  name={'rewardPoints'}
                  value={updateProductData?.rewardPoints}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextInputs
                  title={'Staff Incentive'}
                  name={'staffIncentive'}
                  value={updateProductData?.staffIncentive}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={3} marginTop={0.1}>
                <div style={{ marginBottom: '6px', fontWeight: 'bold', color: 'black' }}>Incentive Type</div>
                <ToggleButtonGroup
                  value={updateProductData?.incentiveType}
                  exclusive
                  onChange={handleIncentiveTypeChange}
                  aria-label="incentive type"
                  fullWidth
                  sx={{
                    borderRadius: '4px',
                    height: '50px',
                    backgroundColor: '#f0f0f0',
                  }}>
                  {incentiveTypes.map((type) => (
                    <ToggleButton
                      key={type}
                      value={type}
                      aria-label={type}
                      sx={{
                        color: '#000',
                        backgroundColor: '#e0e0e0',
                        height: '3.4rem',
                        '&.Mui-selected': {
                          backgroundColor: '#6174DD',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#6174DD',
                          },
                        },
                        '&:not(.Mui-selected)': {
                          '&:hover': {
                            backgroundColor: '#d0d0d0',
                          },
                        }
                      }}
                    >
                      {type}
                    </ToggleButton>
                  ))}

                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={hadleupdateProductData}
                >
                  update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Products;


// import React, { useEffect, useState } from 'react';
// import {
//   Box,
//   Grid,
//   Select,
//   MenuItem,
//   Button,
//   DialogActions,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   ToggleButtonGroup,
//   ToggleButton,
//   IconButton,
// } from '@mui/material';
// // import EditProduct from './EditProduct';
// import { useMain } from '../hooks/useMain';
// // import { useNavigate } from 'react-router';
// import TableCells from 'src/components/Tables/TableCells';
// // import { Search } from '@mui/icons-material';
// import TextInputs from 'src/components/inputs/TextInputs';
// import { ReqField } from 'src/components/CustomComponents/CustomLoader';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import CheckboxInputs from 'src/components/inputs/CheckboxInputs';
// import { MdDelete } from "react-icons/md";
// import { FaRegEdit } from "react-icons/fa";

// export const TrimData = (data) => {
//   if (data?.length < 25) return data;
//   return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
// };

// const initialUpdatedData = {
//   productName: '',
//   mrp: '',
//   volume: '',
//   unit: '',
//   productCount: '',
//   sellPrice: '',
//   // barcode: '',
//   rewardPoints: '',
//   staffIncentive: '',
//   incentiveType: '%',
// };
// const incentiveTypes = ['%', 'INR'];

// const Products = ({ data, setData, getData, checkedProducts, setCheckedProducts }) => {
//   const [updateProductData, setUpdateProductData] = useState(initialUpdatedData);
//   const [isAllChecked, setIsAllChecked] = useState(false);
//   const [selectItemId, setSelectItemId] = useState('');
//   const [openDialogue, setOpenDialogue] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [deleteId, setDeleteId] = useState(null);
//   const [deleteLoading, setDeleteLoading] = useState(false);

//   const { deleteProduct, updateProduct, setShowMessage, activeBranchId } = useMain();

//   const handleUpdate = (item) => {
//     setSelectItemId(item._id);
//     const { branchDetails, createdAt, updatedAt, __v, _id, ...rest } = item;
//     setUpdateProductData((pre) => ({ ...pre, ...rest }));
//     setOpenDialogue(true);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setUpdateProductData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleCloseDialogue = () => {
//     setOpenDialogue(false);
//   };

//   const hadleupdateProductData = async () => {
//     try {
//       setLoading(true);
//       const { availability, ...updateDataWithoutAvailability } = updateProductData;

//       const res = await updateProduct(
//         selectItemId,
//         updateDataWithoutAvailability,
//         `?branchId=${activeBranchId}`
//       );
//       if (res?.statusCode === 200) {
//         setShowMessage({ message: res.message || 'Product details updated successfully', messageType: 'success' });
//         setData([]);
//         getData();
//       } else {
//         setShowMessage({ message: res.message || 'Product details not updated', messageType: 'error' });
//       }
//     } catch (error) {
//       setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
//     } finally {
//       setLoading(false);
//       setOpenDialogue(!openDialogue);
//     }
//   };

//   // const handleDeleteField = async (id) => {
//   //   try {
//   //     const res = await deleteProduct(id, `?branchId=${activeBranchId}`);
//   //     if (res?.statusCode === 200) {
//   //       setData(data?.filter((data) => data?._id !== id));
//   //       setShowMessage({
//   //         message: res.message || 'Product deleted successfully',
//   //         messageType: 'success',
//   //       });
//   //     } else {
//   //       setShowMessage({ message: res.message || 'Product not deleted ', messageType: 'error' });
//   //     }
//   //   } catch (error) {
//   //     setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
//   //   }
//   // };

//   const handleDeleteField = (id) => {
//     setDeleteId(id); // Set the ID to be deleted
//     setOpenDeleteDialog(true); // Open confirmation dialog
//   }


//   const confirmDelete = async () => {
//     setDeleteLoading(true); // Start loading
//     try {
//       const res = await deleteProduct(deleteId, `?branchId=${activeBranchId}`); // Make sure to pass the correct parameters
//       if (res?.statusCode === 200) {
//         setShowMessage({
//           message: res.message || 'Product deleted successfully',
//           messageType: 'success',
//         });
//         setData(prevData => prevData.filter((d) => d?._id !== deleteId)); // Update state
//         await getData(); // Refresh the data after deletion
//       } else {
//         setShowMessage({
//           message: res.message || 'Product not deleted',
//           messageType: 'error',
//         });
//       }
//     } catch (error) {
//       setShowMessage({
//         message: error.message || 'Something went wrong',
//         messageType: 'error',
//       });
//     } finally {
//       setOpenDeleteDialog(false); // Close dialog after operation
//       setDeleteId(null); // Reset delete ID
//       setDeleteLoading(false); // Stop loading
//     }
//   };

//   const handleCloseDeleteDialog = () => {
//     setOpenDeleteDialog(false);
//     setDeleteId(null);
//   };

//   const handleAllCheckboxChange = () => {
//     if (isAllChecked) {
//       setCheckedProducts([]);
//     } else {
//       setCheckedProducts(data);
//     }
//     setIsAllChecked(!isAllChecked);
//   };

//   const handleSignleCheckboxChange = (productId) => {
//     if (checkedProducts.length && checkedProducts.some((check) => check._id === productId)) {
//       setCheckedProducts((prev) => prev.filter((check) => check._id !== productId));
//     } else {
//       const checked = data.filter((item) => item._id === productId);
//       setCheckedProducts((prev) => [...prev, ...checked]); // Create a new array
//     }
//   };

//   useEffect(() => {
//     setIsAllChecked((data?.length && checkedProducts?.length === data?.length) || false);
//   }, [checkedProducts?.length, data?.length]);

//   const handleIncentiveTypeChange = (event, newIncentiveType) => {
//     if (newIncentiveType !== null) {
//       setUpdateProductData({ ...updateProductData, incentiveType: newIncentiveType });
//     }
//   };
//   console.log(updateProductData, "sssssssssssssss");

//   return (
//     <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
//       <table className="table-container mt-3" style={{ width: '100%', borderCollapse: 'collapse' }}>
//         <thead className="table-thead">
//           <tr>
//             <th style={{ width: '10%', padding: '10px' }}>
//               <CheckboxInputs
//                 type={'Heading'}
//                 isAllChecked={isAllChecked}
//                 handleAllCheckboxChange={handleAllCheckboxChange}
//               />
//             </th>
//             {[
//               'Product Name',
//               'Volume',
//               'Count',
//               'Sell (₹)',
//               'MRP',
//               'Reward',
//               'Staff Incentive',
//               'Actions',
//               'Count INC',
//             ].map((h, index) => (
//               <th key={index} style={{ width: '15%', padding: '10px', textAlign: 'left' }}>{h}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody className="table-tbody">
//           {data.map((item) => (
//             <tr key={item._id} style={{ borderBottom: '1px solid #ddd' }}>
//               <td style={{ width: '10%', padding: '10px' }}>
//                 <CheckboxInputs
//                   type={'content'}
//                   handleSignleCheckboxChange={handleSignleCheckboxChange}
//                   checkedItems={checkedProducts}
//                   fieldId={item?._id}
//                 />
//               </td>
//               <td style={{ width: '10%', padding: '5px' }}>{item?.productName}</td>
//               <td style={{ width: '10%', padding: '5px' }}>{`${item?.volume} ${item?.unit}`}</td>
//               <td style={{ width: '10%', padding: '5px' }}>{item?.productCount}</td>
//               <td style={{ width: '10%', padding: '5px' }}>{item?.sellPrice}</td>
//               <td style={{ width: '10%', padding: '5px' }}>{item?.mrp}</td>
//               <td style={{ width: '10%', padding: '5px' }}>{item?.rewardPoints}</td>
//               <td style={{ width: '10%', padding: '5px' }}>{`${item?.staffIncentive ? `${item?.staffIncentive} ${item?.incentiveType}` : ''}`}</td>
//               <td style={{ width: '10%', padding: '5px', display: 'flex', justifyContent: 'space-evenly' }}>
//                 <IconButton onClick={() => handleUpdate(item)} title="Update">
//                   <FaRegEdit style={{ color: '#696969', fontSize: '1.2rem' }} />
//                 </IconButton>
//                 <IconButton onClick={() => handleDeleteField(item._id)} title="Delete">
//                   <MdDelete color='#FF4D4D' style={{ fontSize: '1.2rem' }} />
//                 </IconButton>
//               </td>
//               <td style={{ width: '15%', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//                 <input
//                   type="number"
//                   placeholder="Increase count"
//                   style={{
//                     padding: '5px',
//                     marginRight: '10px',
//                     borderRadius: '4px',
//                     border: '1px solid #ccc',
//                     width: '60%',
//                   }}
//                 />
//                 <button
//                   style={{
//                     backgroundColor: '#4CAF50',
//                     color: 'white',
//                     padding: '6px 12px',
//                     border: 'none',
//                     borderRadius: '4px',
//                     cursor: 'pointer',
//                   }}
//                 >
//                   Add
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <Dialog
//         open={openDeleteDialog}
//         onClose={handleCloseDeleteDialog}
//       >
//         <DialogTitle>Confirm Deletion</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Are you sure you want to delete this product?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDeleteDialog} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={confirmDelete} color="secondary">
//             Delete
//           </Button>
//         </DialogActions>
//       </Dialog>


//       {/* ---------------------- Product details dailog ---------------- */}
//       <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
//         <DialogTitle>Product Details</DialogTitle>
//         <DialogContent>
//           <DialogContentText>Here you can update Product details.</DialogContentText>
//           <Box style={{ marginTop: '20px' }}>
//             <Grid container spacing={3} justify="center">
//               {/* <Grid item xs={12} md={4}>
//                 <TextInputs
//                   title={'Vendor Name'}
//                   name={'vendorName'}
//                   value={updateProductData?.vendorName}
//                   handleChange={handleChange}
//                   type={'text'}
//                   required={false}
//                 />
//               </Grid> */}
//               <Grid item xs={12} md={4}>
//                 <TextInputs
//                   title={'Product Name'}
//                   name={'productName'}
//                   value={updateProductData?.productName}
//                   handleChange={handleChange}
//                   type={'text'}
//                   required={false}
//                 />
//               </Grid>
//               <Grid item xs={12} md={4} container>
//                 <Grid item xs={12} container spacing={3} alignItems="center">
//                   <Grid item xs={12} md={6}>
//                     <TextInputs
//                       title={'MRP'}
//                       name={'mrp'}
//                       value={updateProductData?.mrp}
//                       handleChange={handleChange}
//                       type={'number'}
//                       required={false}
//                     />
//                   </Grid>
//                   <Grid item xs={12} md={6}>
//                     <TextInputs
//                       title={'Volume'}
//                       name={'volume'}
//                       value={updateProductData?.volume}
//                       handleChange={handleChange}
//                       type={'number'}
//                       min={1}
//                       required={false}
//                     />
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Box>

//           <Box style={{ marginTop: '20px' }}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} md={3}>
//                 <div style={{ marginBottom: '5px', fontWeight: 'bold', color: 'black' }}>Incentive Type</div>
//                 <Select
//                   name="unit"
//                   value={updateProductData?.unit}
//                   onChange={handleChange}
//                   fullWidth
//                 >
//                   {['ml', 'l', 'gm', 'kg']?.map((type) => (
//                     <MenuItem key={type} value={type}>
//                       {type}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <TextInputs
//                   title={'Product Count'}
//                   name={'productCount'}
//                   value={updateProductData?.productCount}
//                   handleChange={handleChange}
//                   type={'number'}
//                   required={false}
//                 />
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <TextInputs
//                   title={'Sell Price'}
//                   name={'sellPrice'}
//                   value={updateProductData?.sellPrice}
//                   handleChange={handleChange}
//                   type={'number'}
//                   required={false}
//                 />
//               </Grid>
//             </Grid>
//           </Box>
//           <Box style={{ marginTop: '20px' }}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} md={3}>
//                 <TextInputs
//                   title={'Reward Points'}
//                   name={'rewardPoints'}
//                   value={updateProductData?.rewardPoints}
//                   handleChange={handleChange}
//                   type={'number'}
//                   required={false}
//                 />
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <TextInputs
//                   title={'Staff Incentive'}
//                   name={'staffIncentive'}
//                   value={updateProductData?.staffIncentive}
//                   handleChange={handleChange}
//                   type={'number'}
//                   required={false}
//                 />
//               </Grid>
//               <Grid item xs={12} md={3} marginTop={0.1}>
//                 <div style={{ marginBottom: '6px', fontWeight: 'bold', color: 'black' }}>Incentive Type</div>
//                 <ToggleButtonGroup
//                   value={updateProductData?.incentiveType}
//                   exclusive
//                   onChange={handleIncentiveTypeChange}
//                   aria-label="incentive type"
//                   fullWidth
//                   sx={{
//                     borderRadius: '4px',
//                     height: '50px',
//                     backgroundColor: '#f0f0f0',
//                   }}>
//                   {incentiveTypes.map((type) => (
//                     <ToggleButton
//                       key={type}
//                       value={type}
//                       aria-label={type}
//                       sx={{
//                         color: '#000',
//                         backgroundColor: '#e0e0e0',
//                         height: '3.4rem',
//                         '&.Mui-selected': {
//                           backgroundColor: '#6174DD',
//                           color: '#fff',
//                           '&:hover': {
//                             backgroundColor: '#6174DD',
//                           },
//                         },
//                         '&:not(.Mui-selected)': {
//                           '&:hover': {
//                             backgroundColor: '#d0d0d0',
//                           },
//                         }
//                       }}
//                     >
//                       {type}
//                     </ToggleButton>
//                   ))}

//                 </ToggleButtonGroup>
//               </Grid>
//             </Grid>
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           {loading ? (
//             <ShowLoader />
//           ) : (
//             <>
//               <div style={{ width: '200px', margin: '3rem auto' }}>
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   color="primary"
//                   target="_blank"
//                   sx={{
//                     background: '#6174DD',
//                   }}
//                   onClick={handleCloseDialogue}
//                 >
//                   Cancel
//                 </Button>
//               </div>
//               <div style={{ width: '200px', margin: '3rem auto' }}>
//                 <Button
//                   variant="contained"
//                   fullWidth
//                   color="primary"
//                   target="_blank"
//                   sx={{
//                     background: '#6174DD',
//                   }}
//                   onClick={hadleupdateProductData}
//                 >
//                   update
//                 </Button>
//               </div>
//             </>
//           )}
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default Products;

