import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Snackbar } from '@mui/material';
import TableCells from 'src/components/Tables/TableCells';
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import star_img from 'src/assets/images/star.png';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { BiNote } from "react-icons/bi";

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const FieldData = ({ data, setData, getData, billType }) => {
  const { deleteBilling, setShowMessage, branchData, getAllStaff, activeBranchId, updateBill } =
    useMain();

  const [selectedItem, setSelectedItem] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({});
  const [serviceProviders, setServiceProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateMask, setUpdateMask] = useState(false);
  const [formServices, setFormServices] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const navigate = useNavigate();

  const handleDatails = (id) => {
    navigate(`/billing/${id}`);
  };

  useEffect(() => {
    setUpdateMask(!updateMask);
  }, [branchData?.isMasked]);

  const handleCloseDialogue = () => {
    setSelectedItem(null);
    setUpdateFormData({});
    setOpenDialogue(false);
  };

  const handleUpdate = (item) => {
    const { dateOfBilling, timeOfBilling, amountPaid, paidDues, services, reasonForUpdate } = item;
    setSelectedItem(item);
    setFormServices(item);
    setUpdateFormData({
      dateOfBilling,
      timeOfBilling,
      paidDues,
      reasonForUpdate,
      amountPaid: amountPaid.map(({ amount, paymentType, _id }) => ({ paymentType, amount, _id })),
      services: services?.map((el) => ({
        ...el,
        serviceProvider: el.serviceProvider,
      })),
    });
    setOpenDialogue(true);
  };

  const handleStaffInputChange = (e, index) => {
    const { name, value } = e.target;
    setUpdateFormData((prev) => {
      const updatedServices = [...prev.services];
      if (name === 'serviceProvider') {
        const selectedProvider = serviceProviders.find((provider) => provider._id === value);
        updatedServices[index] = {
          ...updatedServices[index],
          serviceProvider: selectedProvider,
        };
      }
      return { ...prev, services: updatedServices };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((pre) => ({ ...pre, [name]: value }));
  };

  const handleAmountPaidInputChange = (e, index) => {
    const { name, value } = e.target;
    const newAmountPaid = [...updateFormData.amountPaid];

    if (name === "amount") {
      newAmountPaid[index].amount = value;

      // Update the amount due only after the amount is set
      const totalPaid = newAmountPaid.reduce((acc, item) => acc + Number(item.amount), 0);
      const newDues = selectedItem?.totalAmount - totalPaid;

      setUpdateFormData({
        ...updateFormData,
        amountPaid: newAmountPaid,
        paidDues: newDues >= 0 ? newDues : 0 // Ensure dues don't go negative
      });
    } else {
      newAmountPaid[index][name] = value;
      setUpdateFormData({
        ...updateFormData,
        amountPaid: newAmountPaid
      });
    }
  };


  //console.log(updateFormData,"formDataaa")
  const addAmountPaidRow = () => {
    const isUnselectedamountPaid = updateFormData?.amountPaid?.some(
      (item) => item.amount === '' || Number(item.amount) === 0,
    );
    if (isUnselectedamountPaid)
      return setShowMessage({ message: 'please fill the amount field', messageType: 'error' });
    const newAmountPaid = {
      paymentType: 'Cash',
      amount: '',
    };
    const addNewAmountPaid = [...updateFormData.amountPaid, newAmountPaid];
    setUpdateFormData((pre) => ({ ...pre, amountPaid: addNewAmountPaid }));
  };

  const deleteAmountPaidRow = (index) => {
    const updatedAmountPaid = updateFormData?.amountPaid?.filter(
      (_, currentIndex) => currentIndex !== index,
    );
    const paidDues =
      selectedItem.amountPayable +
      updatedAmountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0);
    setUpdateFormData((pre) => ({
      ...pre,
      amountPaid: updatedAmountPaid,
      paidDues,
    }));
  };
  const handleUpdateBill = async () => {
    setLoading(true);
    try {
      const res = await updateBill(selectedItem._id, `?branchId=${activeBranchId}`, updateFormData);
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'bill update successfully',
          messageType: 'success',
        });
        getData();
        setSelectedItem(null);
        setUpdateFormData({});
        setOpenDialogue(false);
      } else {
        setShowMessage({ message: res.message || 'bill not update', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteField = (id) => {
    setDeleteId(id); // Set the ID to be deleted
    setOpenDeleteDialog(true); // Open confirmation dialog
  }

  const confirmDelete = async () => {
    setDeleteLoading(true); // Start loading
    try {
      const res = await deleteBilling(deleteId, activeBranchId);
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Bill deleted successfully',
          messageType: 'success',
        });
        setData(data?.filter((d) => d?._id !== deleteId));
        await getData(); // Refresh the data after deletion
      } else {
        setShowMessage({
          message: res.message || 'Bill not deleted',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Something went wrong',
        messageType: 'error',
      });
    } finally {
      setOpenDeleteDialog(false); // Close dialog after operation
      setDeleteId(null); // Reset delete ID
      setDeleteLoading(false); // Stop loading
    }
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  const fetchServiceProviders = async () => {
    const response = await getAllStaff(activeBranchId);
    setServiceProviders(response?.data);
  };

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            {[
              'Date of Bill',
              'Time of Bill',
              'Client Name',
              'Contact',
              'Gender',
              // 'Sevice Provider',
              billType === 'Bill' ? 'Service Provider' : null,
              'Total',
              'Paid',
              // 'Pending',
              billType === 'Bill' ? 'Pending' : null,
              'Mode of Payment',
              // 'Ratings',
              billType === 'Bill' ? 'Ratings' : null,
              'Actions',
            ].filter(header => header !== null)
              .map((h, index) => {
                return (
                  <th key={index} style={{ width: '100%' }}>
                    {h}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => {
            const originalDate = item?.dateOfBilling;

            // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
            let formattedBillDate = '';
            if (originalDate) {
              const [year, month, day] = originalDate.split('-');
              formattedBillDate = `${day}-${month}-${year}`;
            }
            return (
              <tr key={item._id} style={{ display: "flex", justifyContent: "space-between" }}>
                <td style={{ width: '100%' }}>{formattedBillDate}</td>
                <td style={{ width: '100%' }}>{item?.timeOfBilling}</td>
                <td style={{ width: '100%' }}>{TrimData(item?.clientName)}</td>
                <td style={{ width: '100%' }}>
                  {typeof branchData?.isMasked !== 'boolean' || !item?.clientNumber
                    ? 'loading...'
                    : maskData(item?.clientNumber, branchData?.isMasked)}
                </td>
                <td style={{ width: '90%' }}>{item?.gender}</td>
                <td style={{ width: billType === 'Wallet' ? '10%' : '100%' }}>
                  {item?.services?.map((service) => service?.serviceProvider?.name).join(',  ')}
                </td>
                <td style={{ width: '100%' }}>{item?.amountPayable}</td>
                <td style={{ width: '100%' }}>
                  {item?.amountPaid.reduce((acc, payment) => acc + Number(payment.amount), 0)}
                </td>
                {billType === 'Bill' && (
                  <td style={{ width: '80%' }}>{item?.paidDues}</td>
                )}
                <td style={{ width: '80%' }}>
                  {item?.amountPaid?.map(({ paymentType }) => paymentType).join(',  ')}
                </td>
                {billType === 'Bill' && (
                  <td style={{ width: '130%' }}>
                    {item?.ratings ? (
                      Array.from({ length: item.ratings }, (_, index) => (
                        <img
                          key={index}
                          src={star_img}
                          alt="star"
                          style={{ width: '18px', height: '23px' }}
                        />
                      ))
                    ) : null}
                  </td>
                )}
                <td style={{
                  width: '20%',
                  marginLeft: '-3.2rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '0.5rem'
                }}>
                  <IconButton onClick={() => handleDatails(item._id)} color="primary" title="View Details" style={{ marginRight: '-5px' }}>
                    <AiOutlineInfoCircle style={{ color: '#696969', fontSize: '1.1rem', marginLeft: '1.5rem' }} />
                  </IconButton>
                  <IconButton onClick={() => handleUpdate(item)} color="secondary" title="Update" style={{ marginRight: '24px' }}>
                    <FaRegEdit style={{ color: '#696969', fontSize: '1rem' }} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteField(item._id)} color="error" title="Delete" style={{ marginLeft: '-33px' }}>
                    <MdDelete color='#FF4D4D' style={{ fontSize: '1.2rem', marginTop: '1px' }} />
                  </IconButton>

                  {/* Notes Icon */}
                  <div style={{ position: 'relative' }}>
                    <IconButton
                      onMouseEnter={() => setHoveredItemId(item._id)}
                      onMouseLeave={() => setHoveredItemId(null)}
                      title="View Notes"
                      style={{ marginLeft: '-9px' }}
                    >
                      {/* <BiNote style={{ color: '#696969', fontSize: '1.2rem' }} /> */}
                      <BiNote style={{ color: 'rgb(135 127 127)', fontSize: '1.2rem', marginTop: '0.1rem' }} />
                    </IconButton>

                    {/* Display Notes Tooltip */}
                    {hoveredItemId === item._id && (
                      <div style={{
                        width:'150px',
                        position: 'absolute',
                        top: '50%',
                        right: '120%', // Adjust left/right as needed for spacing
                        transform: 'translateY(-50%)', // Center the tooltip vertically
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        padding: '8px', // Add padding for better spacing
                        zIndex: 1,
                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
                        whiteSpace: 'wrap', // Allow text to wrap
                      }}>
                        {item?.notes ? item.notes : 'No notes'}
                      </div>
                    )}
                  </div>

                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogContent>
          Are you sure you want to delete this bill?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogue}
        onClose={handleCloseDialogue}
        PaperProps={{
          style: {
            maxWidth: '1200px',
            width: '90%',
            minHeight: '80%',
          },
        }}
      >
        <DialogContent>
          <div>
            <div className="mb-3">
              <form className="row">
                <div className="col-md-3 text-start">
                  <label for="exampleInputEmail1" className="form-label">
                    Date of Billing
                  </label>
                  <input
                    type="date"
                    max={new Date().toISOString().split('T')[0]}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={updateFormData?.dateOfBilling}
                    name="dateOfBilling"
                    onChange={handleInputChange}
                  // disabled
                  />
                </div>
                <div className="col-md-3 text-start">
                  <label for="exampleInputEmail1" className="form-label">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="client Number"
                    aria-describedby="emailHelp"
                    value={selectedItem?.clientNumber}
                    name="clientNumber"
                    disabled
                  />
                </div>
                <div className="col-md-3 text-start">
                  <label for="exampleInputEmail1" className="form-label">
                    Client Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="client name"
                    value={selectedItem?.clientName}
                    name="clientName"
                    disabled
                  />
                </div>
                <div className="col-md-3 text-start">
                  <label for="exampleInputEmail1" className="form-label">
                    Time of Billing
                  </label>
                  <input
                    type="time"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={updateFormData?.timeOfBilling}
                    name="timeOfBilling"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3 text-start">
                  <label for="exampleInputEmail1" className="form-label">
                    Service For
                  </label>
                  <select
                    class="form-select"
                    className="form-select"
                    aria-label="Default select example"
                    name="gender"
                    value={selectedItem?.gender}
                    disabled
                  >
                    <option value="" disabled>
                      --select gender--
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </form>
            </div>
            <div class="table-responsive responsive_tbl">
              <table class="table table-bordered">
                <thead>
                  <tr className="list">
                    <th className="col-md-3">Service / Products / Packages</th>
                    <th className="col-md-2">Service provider</th>
                    <th className="col-md-1">Qty</th>
                    <th className="col-sm-2">Discount</th>
                    <th className="col-md-2">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {updateFormData?.services?.map((service, index) => (
                    <tr key={index}>
                      <td className="col-md-3">
                        <div className="d-flex grid gap-2 align-items-center col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Service"
                            name="serviceName"
                            value={service?.serviceName}
                            disabled
                          />
                        </div>
                      </td>
                      <td className="col-md-2">
                        <select
                          id={`serviceProvider-${index}`}
                          className="form-select"
                          aria-label="Default select example"
                          name="serviceProvider"
                          value={service?.serviceProvider?._id || ''}
                          onChange={(e) => handleStaffInputChange(e, index)}
                        >
                          <option value="">Select Provider</option>
                          {serviceProviders.map((provider) => (
                            <option key={provider._id} value={provider._id}>
                              {provider.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          min={1}
                          class="form-control ser_cat"
                          placeholder="Qty"
                          name="quantity"
                          value={service?.quantity}
                          disabled
                        />
                      </td>
                      <td className="col-sm-2">
                        <div className="col-md-12 d-flex grid gap-2 align-items-center">
                          <input
                            type="number"
                            class="form-control ser_cat"
                            min={0}
                            placeholder="0"
                            name="discount"
                            value={service?.discount}
                            disabled
                          />
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            name="discountType"
                            value={formServices?.discountType}
                            disabled
                          >
                            <option value="%">%</option>
                            <option value="INR">INR</option>
                          </select>
                        </div>
                      </td>
                      <td className="col-md-2">
                        <input
                          type="number"
                          min={0}
                          class="form-control ser_cat"
                          placeholder="amount"
                          name="amount"
                          value={service?.amount}
                          disabled
                        />
                      </td>
                    </tr>
                  ))}
                  {selectedItem?.products?.map((formProduct, index) => (
                    <tr key={index}>
                      <td className="col-md-3">
                        <div className="d-flex grid gap-2 align-items-center col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product"
                            name="productName"
                            value={formProduct?.productName}
                            disabled
                          />
                        </div>
                      </td>
                      <td className="col-md-2">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="providerStaff"
                          disabled
                        ></select>
                      </td>
                      <td className="col-sm-1">
                        <input
                          type="number"
                          className="form-control ser_cat"
                          min={1}
                          placeholder="0"
                          name="quantity"
                          value={formProduct?.quantity}
                          disabled
                        />
                      </td>
                      <td className="col-sm-2">
                        <div className="col-md-12 d-flex grid gap-2 align-items-center">
                          <input
                            type="number"
                            min={0}
                            className="form-control ser_cat"
                            placeholder="0"
                            name="discount"
                            value={formProduct?.discount}
                            disabled
                          />
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            name="discountType"
                            value={formProduct?.discountType}
                            disabled
                          >
                            <option value="%">%</option>
                            <option value="INR">INR</option>
                          </select>
                        </div>
                      </td>
                      <td className="col-md-2">
                        <input
                          type="number"
                          min={0}
                          class="form-control ser_cat"
                          name="amount"
                          value={formProduct?.amount}
                          disabled
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>Subtotal</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <span>INR&nbsp;&nbsp;{selectedItem?.subTotal}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Membership</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      {selectedItem?.useMembership && (
                        <>
                          <span style={{ color: 'green' }}>Applied</span>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Taxes</td>
                    <td className="col-md-4">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        name="tax"
                        id="tax"
                        data-validation="required"
                        value={`${selectedItem?.taxes?.taxType === 'Inclusive' ? 'Inclusive' : 'Exclusive'
                          },${selectedItem?.taxes?.SGST},${selectedItem?.taxes?.PGST}`}
                        disabled
                      >
                        <option disabled>Select Taxes</option>
                        <optgroup label="Inclusive Taxes">
                          <option value="Inclusive,0,18">Inclusive Gst on Products(18)</option>
                          <option value="Inclusive,18,0">Inclusive Gst on Service(18)</option>
                        </optgroup>
                        <optgroup label="Exclusive Taxes">
                          <option value="Exclusive,0,18">Exclusive Gst on Products(18)</option>
                          <option value="Exclusive,18,0">Exclusive Gst on Service(18)</option>
                        </optgroup>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Discount</td>
                    <td className="col-md-2">
                      <div className="col-md-12 d-flex grid gap-2 align-items-center">
                        <input
                          type="number"
                          class="form-control ser_cat"
                          min={0}
                          placeholder="0"
                          name="discount"
                          value={selectedItem?.discount?.discount}
                          disabled
                        />
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          name="discountType"
                          value={selectedItem?.discount?.discountType}
                          disabled
                        >
                          <option value="%">%</option>
                          <option value="INR">INR</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> Total</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <span>INR&nbsp;&nbsp;{selectedItem?.totalAmount}</span>
                    </td>
                  </tr>
                  <tr>
                    <td> Coupon</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      {selectedItem?.coupon?.couponId && (
                        <>
                          <span>{selectedItem?.coupon?.couponCode} </span>
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Current Amount</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <span>&nbsp;&nbsp;{selectedItem?.amountPayable}</span>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Net Amount</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold' }}>
                        &nbsp;&nbsp;{selectedItem?.amountPayable}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td> Amount paid</td>
                    <td className="col-md-4" colspan="2">
                      {updateFormData?.amountPaid?.map((amountPaid, index) => (
                        <table key={index}>
                          <tr style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <td>
                              <input
                                type="number"
                                step="1"
                                className="form-control"
                                min="0"
                                name="amount"
                                placeholder="0"
                                value={amountPaid?.amount}
                                onChange={(e) => handleAmountPaidInputChange(e, index)}
                                disabled
                              />
                            </td>
                            <td>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                data-validation="required"
                                name="paymentType"
                                value={amountPaid?.paymentType}
                                onChange={(e) => handleAmountPaidInputChange(e, index)}
                              >
                                <option value="" disabled>
                                  --Select--
                                </option>
                                <option value="Cash">Cash</option>
                                <option value="Credit/Debit Card">Credit/Debit card</option>
                                <option value="Paytm">Paytm</option>
                                <option value="PhonePe">PhonePe</option>
                                <option value="Gpay">Gpay</option>
                              </select>
                            </td>
                            <td>
                              {index === 0 ? (
                                <span class="input-group-btn">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    target="_blank"
                                    sx={{
                                      background: '#6174DD',
                                      fontSize: '15px',
                                      fontWeight: 'bold',
                                    }}
                                    onClick={addAmountPaidRow}
                                  >
                                    <span class="bi bi-plus small"></span>
                                  </Button>
                                </span>
                              ) : (
                                <span class="input-group-btn">
                                  <Button
                                    variant="contained"
                                    color="error"
                                    target="_blank"
                                    sx={{
                                      background: '#bb2d3b',
                                      fontSize: '15px',
                                      fontWeight: 'bold',
                                    }}
                                    onClick={() => deleteAmountPaidRow(index)}
                                  >
                                    <span class="bi bi-dash small"></span>
                                  </Button>
                                </span>
                              )}
                            </td>
                          </tr>
                        </table>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>New Amount due</td>
                    <td className="col-md-2" style={{ textAlign: 'center' }}>
                      <span name="paidDues">
                        INR&nbsp;&nbsp;
                        {updateFormData?.paidDues}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="7">
                      <textarea
                        class="form-control no-resize"
                        rows="2"
                        placeholder="Write bill update reason..."
                        id="textArea"
                        name="reasonForUpdate"
                        value={updateFormData?.reasonForUpdate}
                        onChange={handleInputChange}
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleUpdateBill}
                >
                  update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FieldData;
