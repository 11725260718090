import React, { useEffect, useState } from 'react';
import './analytics.css';
import PageContainer from 'src/components/container/PageContainer';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { useMain } from 'src/views/hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { baseUrl, jwtHeaders } from 'src/context/common/data';
import SalesReport from './SalesReport';
import PackageBillData from 'src/views/Settings/GstBillsData/PackageBillData';
import MembershipBillData from 'src/views/Settings/GstBillsData/MembershipBillData';
import GstBillsPage from 'src/views/Settings/GstBillsData/GstBillsPage';

function Analytics() {
  const { activeBranchId, fetchBillAnalytics, staffCollection, fetchMemberAnalytixs } = useMain();

  const [loading, setLoading] = useState(true);
  const [packagedata, setPackagedata] = useState(null);
  const [memberdata, setMemberdata] = useState(null);
  const [activeComponent, setActiveComponent] = useState('SalesReport'); // Default to 'SalesReport'

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  const getData2 = async (activeBranchId) => {
    try {
      setLoading(true);
      const result = await fetch(
        `${baseUrl}/api/billing/packagebilloverview?branchId=${activeBranchId}`,
        {
          method: 'GET',
          headers: jwtHeaders(),
          redirect: 'follow',
        },
      );
      const res = await result.json();
      if (res.statusCode === 200) {
        setPackagedata(res?.data?.packageBills);
        setMemberdata(res?.data?.membershipBills);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleChange = (event, newComponent) => {
    if (newComponent) {
      setActiveComponent(newComponent);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      getData2(activeBranchId);
    }
  }, [activeBranchId]);

  return (
    <>
      <PageContainer title="Sales Analytics" description="This is Sales Analytics">
        <DashboardCard2 title="Sales Analytics">
          {loading ? (
            <ShowLoader />
          ) : (
            <div>
              {/* <ToggleButtonGroup
                value={activeComponent}
                exclusive
                onChange={handleToggleChange}
                sx={{
                  borderRadius: '30px',
                  height: '40px',
                  backgroundColor: '#f0f0f0',
                  border: '1px solid #cfcece',
                  display: 'flex',
                  gap: '2px', 
                  width: '20%',
                  marginLeft: '0rem'
                }}
              >
                <ToggleButton
                  value="SalesReport"
                  sx={{
                    color: '#000',
                    backgroundColor: '#fafafa',
                    flex: 1,
                    borderRadius: '30px',
                    '&.Mui-selected': {
                      backgroundColor: '#6174DD',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#6174DD',
                      },
                    },
                    '&:not(.Mui-selected)': {
                      '&:hover': {
                        backgroundColor: '#d0d0d0',
                      },
                    },
                  }}
                >
                  Sales Report
                </ToggleButton>
                <ToggleButton
                  value="GstBillsPage"
                  sx={{
                    color: '#000',
                    backgroundColor: '#fafafa',
                    flex: 1,
                    borderRadius: '30px',
                    '&.Mui-selected': {
                      backgroundColor: '#6174DD',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#6174DD',
                      },
                    },
                    '&:not(.Mui-selected)': {
                      '&:hover': {
                        backgroundColor: '#d0d0d0',
                      },
                    },
                  }}
                >
                  GST Bills
                </ToggleButton>
              </ToggleButtonGroup> */}
              <ToggleButtonGroup
                value={activeComponent}
                exclusive
                onChange={handleToggleChange}
                sx={{
                  borderRadius: '30px',
                  height: '40px',
                  backgroundColor: '#f0f0f0',
                  border: '1px solid #cfcece',
                  display: 'flex',
                  gap: '2px',
                  width: '20%',
                  marginLeft: '0rem',
                }}
              >
                <ToggleButton
                  value="SalesReport"
                  sx={{
                    color: '#000',
                    backgroundColor: '#fafafa',
                    flex: 1,
                    borderRadius: '30px',
                    border: 'none', // Remove border
                    '&.Mui-selected': {
                      backgroundColor: '#6174DD',
                      color: '#fff',
                      border: 'none', // Remove border for selected button
                      '&:hover': {
                        backgroundColor: '#6174DD',
                      },
                    },
                    '&:not(.Mui-selected)': {
                      '&:hover': {
                        backgroundColor: '#d0d0d0',
                      },
                    },
                  }}
                >
                  Sales Report
                </ToggleButton>
                <ToggleButton
                  value="GstBillsPage"
                  sx={{
                    color: '#000',
                    backgroundColor: '#fafafa',
                    flex: 1,
                    borderRadius: '30px',
                    border: 'none', // Remove border
                    '&.Mui-selected': {
                      backgroundColor: '#6174DD',
                      color: '#fff',
                      border: 'none', // Remove border for selected button
                      '&:hover': {
                        backgroundColor: '#6174DD',
                      },
                    },
                    '&:not(.Mui-selected)': {
                      '&:hover': {
                        backgroundColor: '#d0d0d0',
                      },
                    },
                  }}
                >
                  GST Bills
                </ToggleButton>
              </ToggleButtonGroup>

              <div className="content">
                {activeComponent === 'SalesReport' && <SalesReport />}
                {activeComponent === 'GstBillsPage' && <GstBillsPage />}
              </div>
            </div>
          )}
        </DashboardCard2>
      </PageContainer>
    </>
  );
}

export default Analytics;