//gst bill button loader added
import React, { useEffect, useRef, useState } from 'react';
import './GstBillsData.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useMain } from 'src/views/hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from 'rsuite';
import { Button, IconButton, InputLabel, MenuItem, Pagination, Select, Typography } from '@mui/material';
import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
import { Box } from '@mui/system';
import { FaDownload } from "react-icons/fa";
import SearchImg from '../../../assets/search.svg';
import { ClearIcon } from '@mui/x-date-pickers-pro';

const GstBillsPage = () => {
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { activeBranchId, getGstData } = useMain();
  const pdfRef = useRef();

  // Fetch GST Bill Details
  const fetchGstBillDetails = async () => {
    setLoading(true);
    try {
      const res = await getGstData(activeBranchId);
      if (res && res.data && res.data.bills) {
        setTableData(res.data.bills);
        setFilterData(res.data.bills); // Set initial filterData
        setIsTableVisible(true);
      } else {
        console.error('Invalid response structure:', res);
      }
    } catch (error) {
      console.error('Error fetching API:', error);
    } finally {
      setLoading(false);
    }
  };

  // Apply filters whenever relevant states change
  useEffect(() => {
    applyFilters();
  }, [tableData, limit, genderBy, page, search, startDate, endDate]);

  const applyFilters = () => {
    let filteredData = tableData.filter((item) => {
      let isGenderMatch = true;
      let isNameMatch = true;
      let isDateMatch = true;

      if (genderBy) {
        isGenderMatch = item.gender === genderBy;
      }

      if (search) {
        const clientName = item.clientName || '';
        const clientNumber = item.clientNumber || '';
        isNameMatch =
          clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
      }

      if (startDate && endDate) {
        const billDate = new Date(item.dateOfBilling);
        isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
      }

      return isGenderMatch && isNameMatch && isDateMatch;
    });

    // Apply pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  const handleDateRangeSelected = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsModalOpen(false);
    setClear(true);
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setGenderBy('');
    setEndDate('');
    setStartDate('');
    setClear(false);
    fetchGstBillDetails(); // Re-fetch all data when cleared
  };

  const downloadPdf = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
      pdf.save('GST_Bill_Details.pdf');
    });
  };

  // const closeTable = () => {
  //   setIsTableVisible(false);
  // };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // Only fetch data on component mount
  useEffect(() => {
    fetchGstBillDetails();
  }, [activeBranchId]);

  return (
    <>
      <div>
        {loading ? (
          <ShowLoader />
        ) : (
          <>
            {isTableVisible && (
              <>
                <div className="dateInput-container">
                  <Grid item xs={2}>
                    <InputLabel>Show per page</InputLabel>
                    <Select
                      style={{
                        borderRadius: '7px',
                        border: '0.5px solid #6174DD',
                        padding: '4px 12px',
                        background: '#FFF',
                        height: '40px',
                      }}
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      {[10, 20, 50].map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                  >
                    {/* <Typography variant="subtitle2" fontWeight={500} component="label">
                      {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
                    </Typography> */}

                    <div style={{ flex: '1 1 150px' }}>
                      <Button
                        variant="outlined"
                        sx={{
                          color: 'black',
                          height: '40px',
                          // width: startDate && endDate ? '100%' : '60%', // Use full width when dates are selected
                          width: startDate && endDate ? '240px' : '150px', // Use full width when dates are selected
                          marginTop: '0.4rem',
                          // marginBottom: '0.3rem',
                          marginLeft: startDate && endDate ? '-16rem' : '-19rem', // Adjust margin based on date selection
                          display: 'flex',
                          justifyContent: startDate && endDate ? 'space-between' : 'center',
                          padding: '0 8px',
                          whiteSpace: 'nowrap', // Prevent text wrapping
                          overflow: 'hidden', // Hide overflow
                          textOverflow: 'ellipsis', // Add ellipsis for overflow text
                          marginRight: '18rem',
                          marginBottom: '-1.3rem',
                        }}
                        onClick={() => setIsModalOpen(true)}
                      >
                        {startDate && endDate ? (
                          <>
                            <span>{`${startDate} to ${endDate}`}</span>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartDate('');
                                setEndDate('');
                                setClear(false);
                                applyFilters();
                              }}
                              sx={{
                                padding: 0,
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                            >
                              <ClearIcon style={{ color: '#000', height: '1rem' }} />
                            </IconButton>
                          </>
                        ) : (
                          'Choose Date'
                        )}
                      </Button>
                    </div>
                  </Grid>


                  <Grid item xs={2} style={{ width: '15rem', marginRight: '-0.2rem' }}>
                    <div style={{marginLeft: '6.3rem'}}>
                      <InputLabel>Search</InputLabel>
                      <div className="search-input">
                        <img src={SearchImg} alt="" />
                        <input
                          type="text"
                          placeholder=""
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        {search && (
                          <IconButton
                            onClick={() => {
                              setSearch('');
                              setClear(false);
                            }}
                            style={{ padding: 1 }}
                          >
                            <ClearIcon style={{
                              // color: '#ff4d4d',
                              color: '#000',
                              // color: '#A2A3AE',
                              marginRight: '-0.2rem', marginTop: '0.1rem',
                              fontSize: '1.1rem'
                            }} />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </Grid>

                  {/* <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        height: '40px',
                        marginTop: '1.7rem',
                        width: '100%',
                        background: '#ff4d4d',
                        '&:hover': { background: '#ff6666' },
                      }}
                      onClick={clearDataHandler}
                    >
                      Clear
                    </Button>
                  </Grid> */}
                </div>

                <DateRangeInputs
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  onDateRangeSelected={handleDateRangeSelected}
                  selectedEndDate={endDate}
                  selectedStartDate={startDate}
                />

                <div className="gst-table-container" ref={pdfRef}>
                  <table>
                    <thead>
                      <tr>
                        {/* <th colSpan="7" style={{ textAlign: 'right' }}>
                          <CloseIcon onClick={closeTable} style={{ cursor: 'pointer' }} />
                        </th> */}
                      </tr>
                      <tr>
                        <th>Date</th>
                        <th>Client Name</th>
                        <th>Invoice No</th>
                        <th>Amount</th>
                        <th>Payable Amount</th>
                        <th>Gst Fair Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterData.map((item) => {
                        const originalDate = item?.dateOfBilling;
                        let formattedBillDate = '';
                        if (originalDate) {
                          const [year, month, day] = originalDate.split('-');
                          formattedBillDate = `${day}-${month}-${year}`;
                        }
                        return (
                          <tr key={item._id}>
                            <td>{formattedBillDate}</td>
                            <td>{item.clientName}</td>
                            <td>{item.invoice}</td>
                            <td>{item.subTotal}</td>
                            <td>{item.amountPayable}</td>
                            <td>{item.amountPayable - item.subTotal}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="download-pdf">
                  {/* <button onClick={downloadPdf}><FaDownload />Download PDF</button> */}
                  <button  title="Download Bills" onClick={downloadPdf}><FaDownload style={{ height: "1.6rem", fontSize: "1.2rem" }} /></button>
                </div>

                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Pagination
                    count={filterData && filterData?.length < limit ? page : page + 1}
                    page={page}
                    color="primary"
                    onChange={(e, page) => setPage(page)}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default GstBillsPage;