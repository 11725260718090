import React, { useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import RadioInputs from 'src/components/inputs/RadioInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useEffect } from 'react';

const initialValue = {
  address: '',
  clientName: '',
  clientNumber: '',
  clientEmail: '',
  gender: '',
  birthday: '',
  anniversary: '',
};

const initialFormValidation = {
  clientNameValidation: false,
  clientNumberValidation: false,
  clientGenderValidation: false,
};

const AddClient = () => {
  const { createClient, activeBranchId, setShowMessage } = useMain();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValue);
  const [formValidation, setFormValidation] = useState(initialFormValidation);

  const clientNameValidation = !formData.clientName.trim();
  const clientNumberValidation = !formData.clientNumber || formData.clientNumber.length !== 10;
  const clientGenderValidation = !formData.gender;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const { address, clientName, gender, clientNumber, clientEmail, anniversary, birthday } =
      formData;

    const validation = {
      clientNameValidation: !clientName.trim(),
      clientNumberValidation: !clientNumber || clientNumber.length !== 10,
      clientGenderValidation: !gender,
    };

    setFormValidation((pre) => ({ ...pre, ...validation }));

    if (Object.values(validation).some((item) => item)) return;

    try {
      setLoading(true);
      const res = await createClient(
        JSON.stringify({
          clientName,
          gender,
          clientEmail,
          clientNumber,
          clientAddress: {
            address,
          },
          anniversary,
          birthday,
          branchDetails: activeBranchId,
        }),
      );
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Client successfully created',
          messageType: 'success',
        });
        setFormData(initialValue);
        setFormValidation(initialFormValidation);
      } else {
        setShowMessage({ message: res.message || 'Something went wrong', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // This logic disables the mouse wheel event
  useEffect(() => {
    const handleWheel = (e) => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <PageContainer title="Add Client" description="this is add Clients">
      <DashboardCard title="Add Client">
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Client Name'}
                name={'clientName'}
                value={formData?.clientName}
                handleChange={handleChange}
                type={'text'}
                placeholder={'Client Name'}
                fieldValidation={clientNameValidation && formValidation.clientNameValidation}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Phone Number'}
                name={'clientNumber'}
                value={formData?.clientNumber}
                handleChange={handleChange}
                type={'number'}
                min={'0'}
                placeholder={'87******22'}
                maxLength={10}
                fieldValidation={clientNumberValidation && formValidation.clientNumberValidation}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RadioInputs
                title={'Gender'}
                name={'gender'}
                value={formData?.gender}
                handleChange={handleChange}
                options={[
                  { title: 'M', value: 'Male' },
                  { title: 'F', value: 'Female' },
                ]}
                fieldValidation={clientGenderValidation && formValidation.clientGenderValidation}
              />
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Email'}
                name={'clientEmail'}
                value={formData?.clientEmail}
                handleChange={handleChange}
                type={'email'}
                placeholder={'Email'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Address'}
                name={'address'}
                value={formData?.address}
                handleChange={handleChange}
                type={'text'}
                placeholder={'Address'}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInputs
                title={'BirthDay'}
                required={false}
                name={'birthday'}
                value={formData?.birthday}
                handleChange={handleChange}
                type={'date'}
              />
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Anniversary'}
                name={'anniversary'}
                value={formData?.anniversary}
                handleChange={handleChange}
                type={'date'}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <div
                            style={{
                                width: '200px',
                                height: '38px',
                                borderRadius: '7px',
                                color: '#6174DD',
                                border: '0.5px solid #6174DD',
                                paddingLeft: '8px',
                                marginRight: '10px',
                            }}
                        >
                            <Button variant="text" color="primary" >
                                Filter
                            </Button>
                        </div> */}

            <div style={{ width: '200px' }}>
              {loading ? (
                <ShowLoader />
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleSubmit}
                >
                  Add Client
                </Button>
              )}
            </div>
          </div>
        </Box>
      </DashboardCard>
    </PageContainer>
  );
};

export default AddClient;
