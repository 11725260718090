
import Chart from 'react-apexcharts';

const BarGraph = ({ data }) => {
  const serviceAmounts = data.series.map(item => item.serviceAmount ?? 0); 
  const productAmounts = data.series.map(item => item.productAmount ?? 0); 

  let graphObject = {
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: data?.categories, // Assuming 'categories' is provided in the data prop
      },
      title: {
        text: 'As per Service and Product',
        align: 'center',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: 'green',
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      colors: ['#008FFB', '#FFB6C1'], // Blue for Service, Light Pink for Product
      dataLabels: {
        enabled: true, // Show the data values on the bars
        formatter: function (val) {
          return val === 0 ? '0' : val; // Ensure 0 is displayed as 0
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['black'], // Data label color
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val === 0 ? '0' : val; // Ensure tooltip shows 0 correctly
          },
        },
      },
    },
    series: [
      {
        name: 'Service',
        data: serviceAmounts, // Blue bars for Service
      },
      {
        name: 'Product',
        data: productAmounts, // Light Pink bars for Product
      },
    ],
  };

  return (
    <div>
      <Chart
        options={graphObject.options}
        series={graphObject.series}
        type="bar"
        width="650"
      />
    </div>
  );
};

export default BarGraph;
