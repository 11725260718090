
import React from 'react';
import Chart from 'react-apexcharts';
import './StaffBarGraph.css'; // Import your custom CSS file for styles
import ShowLoader from 'src/components/CustomComponents/ShowLoader';

const StaffBarGraph = ({ data, isLoading }) => {
    const hasData = data?.categories?.length > 0 && data?.series?.length > 0;

    const graphObject = {
        options: {
            chart: {
                id: 'staff-bar',
            },
            xaxis: {
                categories: data?.categories,
                title: {
                    text: 'Staff Names',
                },
            },
            yaxis: {
                title: {
                    text: 'Service Count',
                },
            },
            tooltip: {
                custom: ({ seriesIndex, dataPointIndex, w }) => {
                    const serviceDetails = data?.services[dataPointIndex] || [];
                    return `
                        <div class="custom-tooltip">
                            <div><strong>Staff: ${w.globals.labels[dataPointIndex]}</strong></div>
                            <div><table>
                                <thead>
                                    <tr><th>Service Name</th><th>Service Count</th></tr>
                                </thead>
                                <tbody>
                                    ${serviceDetails.map(service => `
                                        <tr>
                                            <td>${service.name}</td>
                                            <td>${service.count}</td>
                                        </tr>
                                    `).join('')}
                                </tbody>
                            </table></div>
                        </div>
                    `;
                },
            },
        },
        series: [
            {
                name: 'Service Count',
                data: data?.series,
            },
        ],
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            {isLoading ? (
                 <ShowLoader />
            ) : hasData ? (
                <Chart options={graphObject.options} series={graphObject.series} type="bar" width="800" />
            ) : (
                <div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', color: '#ff4d4d' }}>
                    No Data Here
                </div>
            )}
        </div>
    );
};

export default StaffBarGraph;
