import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Select,
  MenuItem,
  Snackbar,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import TableCells from 'src/components/Tables/TableCells';
import TextInputs from 'src/components/inputs/TextInputs';
import { ReqField } from 'src/components/CustomComponents/CustomLoader';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

// export const TrimData = (data) => {
//     if (data.length < 25)
//         return data;
//     return data.substring(0, 15) + '...' + data.substring(data?.length - 10);
// };
const initialUpdatedData = {
  couponCode: '',
  discount: 0,
  discountType: '',
  minimumBillAmount: 0,
  maximumDiscountAmount: 0,
  couponPerUser: 1,
  validTill: '',
  rewardPoints: 0,
};
const discount_type = ['%', 'INR'];
const Coupons = ({ data, setData, getCoupons }) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [updateCouponData, setUpdatedCouponData] = useState(initialUpdatedData);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete confirmation dialog
  const [deleteId, setDeleteId] = useState(null); // Store the ID of the item to be deleted
  const [deleteLoading, setDeleteLoading] = useState(false); // State for delete loading
  const { deleteCoupon, updateCoupon, setShowMessage, activeBranchId } = useMain();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedCouponData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = (item) => {
    setSelectedItemId(item._id);
    const {
      couponCode,
      discount,
      discountType,
      minimumBillAmount,
      maximumDiscountAmount,
      couponPerUser,
      validTill,
      rewardPoints,
    } = item;
    setUpdatedCouponData((pre) => {
      return {
        ...pre,
        couponCode,
        discount,
        discountType,
        minimumBillAmount,
        maximumDiscountAmount,
        couponPerUser,
        validTill,
        rewardPoints,
      };
    });
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const hadleupdateCouponData = async () => {
    try {
      setLoading(true);
      const res = await updateCoupon(
        selectedItemId,
        updateCouponData,
        `?branchId=${activeBranchId}`,
      );
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Coupon Details updated successfully',
          messageType: 'success',
        });
        setData([]);
        getCoupons();
      } else {
        setShowMessage({
          message: res.message | 'Coupon details not Updated',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(false);
    }
  };

  // const handleDeleteField = async (id) => {
  //   try {
  //     const res = await deleteCoupon(id, `?branchId=${activeBranchId}`);
  //     if (res?.statusCode === 200) {
  //       setData(data?.filter((data) => data?._id !== id));
  //       setShowMessage({
  //         message: res.message || 'Coupon deleted Successfully',
  //         messageType: 'success',
  //       });
  //     } else {
  //       setShowMessage({ message: res.message || 'Coupon not deleted', messageType: 'error' });
  //     }
  //   } catch (error) {
  //     setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
  //   } finally {
  //     setOpenDialogue(false);
  //   }
  // };

  
  const handleDeleteField = (id) => {
    setDeleteId(id); // Set the ID to be deleted
    setOpenDeleteDialog(true); // Open confirmation dialog
  }

  const handleDiscountTypeChange = (event, newDiscountType) => {
    if (newDiscountType !== null) {
      setUpdatedCouponData({ ...updateCouponData, discountType: newDiscountType });
    }
  };

  const confirmDelete = async () => {
    setDeleteLoading(true); // Start loading
    try {
      const res = await deleteCoupon(deleteId, `?branchId=${activeBranchId}`); // Ensure the API is called correctly
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Coupon deleted successfully',
          messageType: 'success',
        });
        // Update the state to remove the deleted coupon
        setData((prevData) => prevData.filter((d) => d._id !== deleteId));
        await getCoupons(); // Refresh the data after deletion
      } else {
        setShowMessage({
          message: res.message || 'Coupon not deleted',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Something went wrong',
        messageType: 'error',
      });
    } finally {
      setOpenDeleteDialog(false); // Close dialog after operation
      setDeleteId(null); // Reset delete ID
      setDeleteLoading(false); // Stop loading
    }
  };
  
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  console.log(updateCouponData, "cccccccccccccccc");

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            {[
              'Coupon',
              'Discount',
              'Min Amount',
              'Max Discount',
              'Validity',
              'Coupon/User',
              'Reward Points',
              // 'Details',
              'Actions',
            ].map((h, index) => {
              return (
                <th key={index} style={{ width: '100%' }}>
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((coupon) => {
            const originalDate = coupon?.validTill;

            // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
            let formattedValidDate = '';
            if (originalDate) {
              const [year, month, day] = originalDate.split('-');
              formattedValidDate = `${day}-${month}-${year}`;
            }
            return (
              <tr key={coupon._id}>
                <td style={{ width: '100%' }}>{coupon?.couponCode}</td>
                <td style={{ width: '100%' }}>
                  {coupon?.discount} {coupon?.discountType}
                </td>
                <td style={{ width: '100%' }}>{coupon?.minimumBillAmount}</td>
                <td style={{ width: '100%' }}>{coupon?.maximumDiscountAmount}</td>
                <td style={{ width: '100%' }}>{formattedValidDate}</td>
                <td style={{ width: '100%' }}>{coupon?.couponPerUser}</td>
                <td style={{ width: '100%' }}>{coupon?.rewardPoints}</td>
                {/* <td style={{ width: '100%' }}>
                  <TableCells
                    type="button"
                    handleDeleteField={handleDeleteField}
                    handleUpdate={() => handleUpdate(coupon)}
                    // handleDetails={() => {}}
                    field={coupon?._id}
                  />
                </td> */}
                <td style={{
                  width: '100%',
                }}>
                  <IconButton
                    onClick={() => handleUpdate(coupon)}
                    color="secondary"
                    title="Update"
                    style={{ marginRight: '24px' }}
                  >
                    <FaRegEdit style={{ color: '#696969', fontSize: '1rem' }} />
                  </IconButton>

                  <IconButton
                    onClick={() => handleDeleteField(coupon?._id)}
                    color="error"
                    title="Delete"
                  >
                    <MdDelete color='#FF4D4D' style={{ fontSize: '1.2rem', marginTop: '1px',marginLeft:'-38px'}} />
                  </IconButton>
                </td>

              </tr>
            );
          })}
        </tbody>
      </table>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogContent>
          Are you sure you want to delete this coupon?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>


      {/* ---------------------- Coupan Details Dialog ---------------- */}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>Coupon Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update Coupon details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Coupon Code'}
                  name={'couponCode'}
                  value={updateCouponData?.couponCode}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Discount'}
                  name={'discount'}
                  value={updateCouponData?.discount}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4} marginTop='0.1rem'>
                <div style={{ marginBottom: '3px', fontWeight: 'bold', color: 'black' }}>Discount Type</div>
                {/* <Select
                  name="discountType"
                  value={updateCouponData?.discountType}
                  onChange={handleChange}
                  fullWidth
                >
                  {discount_type.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select> */}
                <ToggleButtonGroup
                  value={updateCouponData?.discountType}
                  exclusive
                  onChange={handleDiscountTypeChange}
                  aria-label="discount type"
                  fullWidth
                  sx={{
                    borderRadius: '4px',
                    height: '50px',
                    backgroundColor: '#f0f0f0',
                    marginTop: '0.1rem'
                  }}
                >
                  {discount_type.map((type) => (
                    <ToggleButton
                      key={type}
                      value={type}
                      aria-label={type}
                      sx={{
                        color: '#000',
                        backgroundColor: '#e0e0e0',
                        height: '3.3rem',
                        '&.Mui-selected': {
                          backgroundColor: '#6174DD',
                          color: '#fff',

                          '&:hover': {
                            backgroundColor: '#6174DD',
                          },
                        },
                        '&:not(.Mui-selected)': {
                          '&:hover': {
                            backgroundColor: '#d0d0d0',
                          },
                        }
                      }}
                    >{type}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Minimum Bill Amount'}
                  name={'minimumBillAmount'}
                  value={updateCouponData?.minimumBillAmount}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Max Discount in Rs.'}
                  name={'maximumDiscountAmount'}
                  value={updateCouponData?.maximumDiscountAmount}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Coupon Per User'}
                  name={'couponPerUser'}
                  value={updateCouponData?.couponPerUser}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Valid Till'}
                  name={'validTill'}
                  value={updateCouponData?.validTill}
                  handleChange={handleChange}
                  type={'date'}
                  min={new Date().toISOString().split('T')[0]}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Reward Points'}
                  name={'rewardPoints'}
                  value={updateCouponData?.rewardPoints}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={hadleupdateCouponData}
                >
                  update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Coupons;
