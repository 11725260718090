import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  DialogActions,
  IconButton,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import TableCells from 'src/components/Tables/TableCells';
import TextInputs from 'src/components/inputs/TextInputs';
import SelectInputs from 'src/components/inputs/SelectInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import CheckboxInputs from 'src/components/inputs/CheckboxInputs';
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};
const initialValue = {
  serviceName: '',
  category: '',
  duration: 0,
  price: 0,
  serviceFor: '',
};

const serviceOptions = [
  {
    name: 'Male',
  },
  {
    name: 'Female',
  },
  {
    name: 'Both',
  },
];
const FieldData = ({ data, setData, getServices, checkedServices, setCheckedServices }) => {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [updatedServiceData, setUpdatedServiceData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { deleteService, updateService, setShowMessage, activeBranchId } = useMain();

  const handleCloseDialogue = () => {
    setOpenDialogue(!openDialogue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedServiceData((pre) => ({ ...pre, [name]: value }));
  };

  const handleUpdate = (item) => {
    setSelectedServiceId(item._id);
    const { serviceName, category, duration, price, serviceFor } = item;
    setUpdatedServiceData((pre) => {
      return { ...pre, serviceName, category, duration, price, serviceFor };
    });
    setOpenDialogue(!openDialogue);
  };

  const handleUpdateService = async () => {
    try {
      setLoading(true);
      const res = await updateService(
        selectedServiceId,
        updatedServiceData,
        `?branchId=${activeBranchId}`,
      );
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Service updated successfully',
          messageType: 'success',
        });
        setData([]);
        getServices();
      } else {
        setShowMessage({
          message: res.message || 'Service details not updated',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(!openDialogue);
    }
  };

  // const handleDeleteField = async (id) => {
  //   try {
  //     const res = await deleteService(id, `?branchId=${activeBranchId}`);
  //     if (res?.statusCode === 200) {
  //       setData(data?.filter((data) => data?._id !== id));
  //       setShowMessage({
  //         message: res.message || 'Service deleted successfully',
  //         messageType: 'success',
  //       });
  //     } else {
  //       setShowMessage({
  //         message: res.message || 'Service not deleted successfully',
  //         messageType: 'error',
  //       });
  //     }
  //   } catch (error) {
  //     setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
  //   }
  // };
  const handleDeleteField = (id) => {
    setDeleteId(id); // Set the ID to be deleted
    setOpenDeleteDialog(true); // Open confirmation dialog
  }

  // const confirmDelete = async () => {
  //   setDeleteLoading(true); // Start loading
  //   try {
  //     const res = await deleteService(deleteId, activeBranchId);
  //     if (res?.statusCode === 200) {
  //       setShowMessage({
  //         message: res.message || 'Service deleted successfully',
  //         messageType: 'success',
  //       });
  //       setData(data?.filter((d) => d?._id !== deleteId));
  //       await getServices(); // Refresh the data after deletion
  //     } else {
  //       setShowMessage({
  //         message: res.message || 'Service not deleted',
  //         messageType: 'error',
  //       });
  //     }
  //   } catch (error) {
  //     setShowMessage({
  //       message: error.message || 'Something went wrong',
  //       messageType: 'error',
  //     });
  //   } finally {
  //     setOpenDeleteDialog(false); // Close dialog after operation
  //     setDeleteId(null); // Reset delete ID
  //     setDeleteLoading(false); // Stop loading
  //   }
  // };

  const confirmDelete = async () => {
    try {
      const res = await deleteService(deleteId, `?branchId=${activeBranchId}`);
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Service deleted successfully',
          messageType: 'success',
        });
        setData(data?.filter((d) => d?._id !== deleteId));
        await getServices(); // Refresh the data after deletion
      } else {
        setShowMessage({
          message: res.message || 'Service not deleted',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Something went wrong',
        messageType: 'error',
      });
    } finally {
      setOpenDeleteDialog(false);
      setDeleteId(null);
    }
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setCheckedServices([]);
    } else {
      setCheckedServices(data);
    }
    setIsAllChecked(!isAllChecked);
  };

  const handleSignleCheckboxChange = (serviceId) => {
    if (checkedServices.length && checkedServices.some((check) => check._id === serviceId)) {
      setCheckedServices((prev) => prev.filter((check) => check._id !== serviceId));
    } else {
      const checked = data.filter((item) => item._id === serviceId);
      setCheckedServices((prev) => [...prev, ...checked]); // Create a new array
    }
  };

  useEffect(() => {
    setIsAllChecked((data?.length && checkedServices?.length === data?.length) || false);
  }, [checkedServices?.length, data?.length]);

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            <th style={{ width: '20%' }}>
              <CheckboxInputs
                type={'Heading'}
                isAllChecked={isAllChecked}
                handleAllCheckboxChange={handleAllCheckboxChange}
              />
            </th>
            {['Name', 'Service For', 'Category', 'Duration', 'Price', 'Actions'].map((h, index) => {
              return (
                <th key={index} style={{ width: '100%' }}>
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => {
            return (
              <tr key={item._id}>
                <td style={{ width: '20%' }}>
                  <CheckboxInputs
                    type={'content'}
                    handleSignleCheckboxChange={handleSignleCheckboxChange}
                    checkedItems={checkedServices}
                    fieldId={item?._id}
                  />
                </td>
                <td style={{ width: '100%' }}>{TrimData(item?.serviceName)}</td>
                <td style={{ width: '100%' }}>{TrimData(item?.serviceFor)}</td>
                <td style={{ width: '100%' }}>{item?.category}</td>
                <td style={{ width: '100%' }}>{item?.duration ? item?.duration + ' min' : ''}</td>
                <td style={{ width: '100%' }}>{'Rs.' + item?.price}</td>

                {/* <td style={{ width: '100%' }}>
                  <TableCells
                    type="button"
                    handleDeleteField={handleDeleteField}
                    handleUpdate={() => handleUpdate(item)}
                    // handleDetails={()=>{}}
                    field={item?._id}
                  />
                </td> */}
                <td style={{
                  width: '100%'
                }}>
                  <IconButton onClick={() => handleUpdate(item)} color="secondary" title="Update" style={{ marginRight: '24px' }}>
                    <FaRegEdit style={{ color: '#696969', fontSize: '1rem' }} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteField(item._id)} color="error" title="Delete" style={{ marginLeft: '-33px' }}>
                    <MdDelete color='#FF4D4D' style={{ fontSize: '1.2rem', marginTop: '1px' }} />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogContent>
          Are you sure you want to delete this Service?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>


      {/* ---------------------- Service Details Dialog ---------------- */}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>Service Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update Service details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Service Name'}
                  name={'serviceName'}
                  value={updatedServiceData?.serviceName}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Category Name'}
                  name={'category'}
                  value={updatedServiceData?.category}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Price'}
                  name={'price'}
                  value={updatedServiceData?.price}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Duration (in minutes)'}
                  name={'duration'}
                  value={updatedServiceData?.duration}
                  handleChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectInputs
                  title={'Services For'}
                  options={serviceOptions}
                  name={'serviceFor'}
                  value={updatedServiceData?.serviceFor}
                  handleChange={handleChange}
                  optionTitle={'name'}
                  optionValue={'name'}
                  required={false}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleUpdateService}
                >
                  update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FieldData;
