//pagination correction
import React, { useEffect, useState, useCallback } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import FieldData from './FieldData';
import { Box, Button, Grid, IconButton, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import SearchImg from '../../assets/search.svg';
import { useNavigate } from 'react-router';
import { ClearIcon } from '@mui/x-date-pickers-pro';

const filterActiveStatusWise = [
  { label: 'APPROVED', value: 'APPROVED' },
  { label: 'PENDING', value: 'PENDING' },
  { label: 'BOTH', value: 'BOTH' }
];

const AllBranch = () => {
  const { ownerData, fetchAllBranch } = useMain();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [activeStatus, setActiveStatus] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);

  const navigate = useNavigate();

  const fetchAllBranches = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchAllBranch(ownerData._id);
      if (res.statusCode === 200) {
        setData(res.data || []);
        setFilteredData(res.data || []);
      } else {
        setData([]);
        setFilteredData([]);
      }
    } catch (error) {
      setData([]);
      setFilteredData([]);
    } finally {
      setLoading(false);
    }
  }, [fetchAllBranch, ownerData._id]);

  console.log(ownerData,"od")
  console.log(ownerData._id,"id")
  useEffect(() => {
    if (ownerData._id) {
      fetchAllBranches();
    }
  }, [fetchAllBranches, ownerData._id]);

  useEffect(() => {
    applyFilters();
    // }, [data, search, activeStatus, limit, page]);
  }, [data, search, activeStatus, limit]);

  // const applyFilters = () => {
  //   let filtered = data.filter(item => {
  //     let isStatusMatch = true;
  //     let isNameMatch = true;

  //     if (activeStatus) {
  //       isStatusMatch = item.activeStatus === activeStatus;
  //     }

  //     if (search) {
  //       isNameMatch = item.branchName.toLowerCase().includes(search.toLowerCase());
  //     }

  //     return isStatusMatch && isNameMatch;
  //   });

  //   const startIndex = (page - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   filtered = filtered.slice(startIndex, endIndex);

  //   setFilteredData(filtered);
  // };

  // const applyFilters = () => {
  //   let filtered = data.filter(item => {
  //     let isStatusMatch = !activeStatus || item.activeStatus === activeStatus;
  //     let isNameMatch = !search || item.branchName.toLowerCase().includes(search.toLowerCase());
  //     return isStatusMatch && isNameMatch;
  //   });

  //   setFilteredData(filtered);
  //   setPage(1); // Reset page to 1 whenever filters change
  // };

  const applyFilters = () => {
    let filtered = data.filter(item => {
      let isStatusMatch = true;
      let isNameMatch = !search || item.branchName.toLowerCase().includes(search.toLowerCase());

      if (activeStatus) {
        if (activeStatus === 'BOTH') {
          isStatusMatch = true; // Show all if "Both" is selected
        } else {
          isStatusMatch = item.activeStatus === activeStatus;
        }
      }

      return isStatusMatch && isNameMatch;
    });

    setFilteredData(filtered);
    setPage(1); // Reset page to 1 whenever filters change
  };


  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setActiveStatus('');
    // setClear(true);
    fetchAllBranches(); // Re-fetch all data when cleared
  };

  const totalPages = Math.ceil(filteredData.length / limit);

  return (
    <PageContainer title="Branches" description="This contains all products">
      <DashboardCard2 title="Branches">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <InputLabel style={{ marginRight: '0.5rem' }}>Show per page</InputLabel>
            <Select
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
              }}
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '1rem' }}>
              <InputLabel style={{ marginRight: '0.5rem' }}>Active Status</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                  width: '140px',
                }}
                value={activeStatus}
                onChange={(e) => setActiveStatus(e.target.value)}
              >
                {filterActiveStatusWise.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div onClick={() => document.getElementById('search-input').focus()}>
              <InputLabel style={{ marginRight: '0.5rem' }}>Search</InputLabel>
              <div className="search-input" style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={SearchImg}
                  style={{ marginRight: '0.5rem' }}
                />
                <input
                  id="search-input"
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ borderRadius: '7px' }}
                />
                {search && (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                      // setClear(false);
                    }}
                    style={{ padding: 1 }}
                  >
                    <ClearIcon style={{
                      color: '#000',
                      marginRight: '-0.2rem',
                      marginTop: '0.1rem',
                      fontSize: '1.1rem'
                    }} />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <ShowLoader />
        ) : filteredData.length > 0 ? (
          <>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <FieldData data={filteredData.slice((page - 1) * limit, page * limit)} setData={setData} getData={fetchAllBranches} ownerData={ownerData}/>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          'No data found'
        )}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            // count={Math.ceil(data.length / limit)}
            count={totalPages}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer >
  );
};

export default AllBranch;
