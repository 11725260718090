import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import './ClientDetails.css';
import { useLocation, useNavigate, useParams } from 'react-router';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import { useMain } from 'src/views/hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Button,
  Box,
} from '@mui/material';
import DiscountMembershipDetail from './DiscountMembershipDetail';
import ServiceMembershipDetail from './ServiceMembershipDetail';
import { MessageData } from './messageData';
import TextInputs from 'src/components/inputs/TextInputs';
import RadioInputs from 'src/components/inputs/RadioInputs';
import PackageDetails from 'src/views/Membership/OurPackages/PackageDetails';

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value?.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const initialUpatedData = {
  clientName: '',
  clientNumber: '',
  clientAddress: '',
  clientEmail: '',
  gender: '',
  birthday: '',
  anniversary: '',
};

function ClientDetails() {
  const { clientId } = useParams();

  const [billData, setBillData] = useState([]);
  const [data, setData] = useState({});
  const [packageData, setPackageData] = useState({})
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openDialogues, setOpenDialogues] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [openPackageDialog, setOpenPackageDialog] = useState(false); // State for package dialog
  const [updatedClientId, setUpdateClientId] = useState('');

  const [clientData, setClientData] = useState({});

  const {
    updateClient,
    setShowMessage,
    branchData,
    activeBranchId,
    fetchClientById,
    fetchBillByClientNumber,
  } = useMain();

  const navigate = useNavigate();
  const { state } = useLocation()

  const [updatedClientData, setUpdatedClientData] = useState(initialUpatedData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'address') {
      setUpdatedClientData((prevData) => ({
        ...prevData,
        clientAddress: { [name]: value },
      }));
    } else {
      setUpdatedClientData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleCloseDialogues = () => {
    setOpenDialogues(false);
  };
  const handleOpenDialogues = () => {
    setOpenDialogues(true);
  };

  const handleClosePackageDialog = () => {
    setOpenPackageDialog(false);
  };

  const handleOpenPackageDialog = () => {
    setOpenPackageDialog(true);
  };

  const handleUpdate = async (item) => {
    setUpdateClientId(item._id);
    setUpdatedClientData({
      ...updatedClientData,
      clientName: item?.clientName,
      clientNumber: item?.clientNumber,
      clientAddress: item?.clientAddress,
      gender: item?.gender,
      clientEmail: item?.clientEmail,
      birthday: item?.birthday,
      anniversary: item?.anniversary,
    });
    setOpenDialogue(true);
  };

  const hadleupdateClientData = async () => {
    try {
      setDialogLoading(true);
      const res = await updateClient(
        updatedClientId,
        updatedClientData,
        `?branchId=${activeBranchId}`,
      );
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Client Details updated successfully',
          messageType: 'success',
        });
        setOpenDialogue(false);
        await getData(clientId);
      } else {
        setShowMessage({
          message: res.message || 'Client Details not updated',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setDialogLoading(false);
    }
  };

  const handleSendMembershipDetails = () => {
    let url = MessageData(data, branchData);
    window.open(url, '_blank');
  };

  const handleSendPackageDetails = () => {
    let url = MessageData(data, branchData,);
    window.open(url, '_blank');
  };

  const getData = async (clientId) => {
    try {
      setLoading(true);
      const res = await fetchClientById(clientId, `?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        setData(res.data);
        fetchBillData(res.data?.clientNumber);
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchBillData = async (number) => {
    try {
      const resp = await fetchBillByClientNumber(number, `?branchId=${activeBranchId}`);
      if (resp.statusCode === 200) {
        setBillData(resp.data?.reverse());
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (clientId) getData(clientId);
    return () => {
      setData({});
      setPackageData({})
      setBillData([]);
    };
  }, [clientId, activeBranchId]);

  console.log(data);

  return (
    <>
      <PageContainer title="Client Details" description="this is ClientDetails Page">
        <DashboardCard2 title="Client Details">
          {loading ? (
            <ShowLoader />
          ) : Object.keys(data).length > 0 ? (
            <>
              {/* <button
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  float: 'right',
                  backgroundColor: '#6174DD',
                  padding: '10px 15px',
                  color: '#fff',
                  borderRadius: '5px',
                  fontSize: '14px',
                  border: 'none',
                }}
                onClick={() => handleUpdate(data)}
              >
                Update
              </button> */}

              {/* membership button */}
              <button
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  float: 'right',
                  backgroundColor: '#6174DD',
                  padding: '10px 15px',
                  color: '#fff',
                  borderRadius: '5px',
                  fontSize: '14px',
                  border: 'none',
                  marginRight: '20px',
                }}
                onClick={handleOpenDialogues}
              >
                Membership
              </button>

              {/* package button */}
              <button
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  float: 'right',
                  backgroundColor: '#6174DD',
                  padding: '10px 15px',
                  color: '#fff',
                  borderRadius: '5px',
                  fontSize: '14px',
                  border: 'none',
                  marginRight: '20px',
                }}
                onClick={handleOpenPackageDialog}
              >
                Package
              </button>

              {/* ---------------------- Client Details Dialog ---------------- */}
              <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
                <DialogTitle>Client Details</DialogTitle>
                <DialogContent>
                  <DialogContentText>Here you can update client details.</DialogContentText>
                  <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextInputs
                          title={'Client Name'}
                          name={'clientName'}
                          value={updatedClientData?.clientName}
                          handleChange={handleInputChange}
                          type={'text'}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInputs
                          title={'Contact No'}
                          name={'clientNumber'}
                          value={updatedClientData?.clientNumber}
                          handleChange={handleInputChange}
                          type={'number'}
                          maxLength={10}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <RadioInputs
                          title={'Gender'}
                          name={'gender'}
                          value={updatedClientData?.gender}
                          handleChange={handleInputChange}
                          options={[
                            { title: 'M', value: 'Male' },
                            { title: 'F', value: 'Female' },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextInputs
                          required={false}
                          title={'Email'}
                          name={'clientEmail'}
                          value={updatedClientData?.clientEmail}
                          handleChange={handleInputChange}
                          type={'email'}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInputs
                          required={false}
                          title={'Address'}
                          name={'address'}
                          value={updatedClientData?.clientAddress?.address}
                          handleChange={handleInputChange}
                          type={'text'}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextInputs
                          title={'BirthDay'}
                          required={false}
                          name={'birthday'}
                          value={updatedClientData?.birthday}
                          handleChange={handleInputChange}
                          type={'date'}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextInputs
                          required={false}
                          title={'Anniversary'}
                          name={'anniversary'}
                          value={updatedClientData?.anniversary}
                          handleChange={handleInputChange}
                          type={'date'}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <>
                    <div style={{ width: '200px', margin: '3rem auto' }}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        target="_blank"
                        sx={{
                          background: '#6174DD',
                        }}
                        onClick={handleCloseDialogue}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div style={{ width: '200px', margin: '3rem auto' }}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        target="_blank"
                        sx={{
                          background: '#6174DD',
                        }}
                        onClick={hadleupdateClientData}
                      >
                        update
                      </Button>
                    </div>
                  </>
                </DialogActions>
              </Dialog>

              {/*--------- Memebership detail page------------------------------- */}
              <Dialog open={openDialogues} onClose={handleCloseDialogues}>
                <DialogTitle>Membership Details</DialogTitle>
                <DialogContent>
                  {data?.membershipDetails?.membershipType ? (
                    data?.membershipDetails?.membershipType === 'discountType' ? (
                      <DiscountMembershipDetail data={data?.membershipDetails} />
                    ) : (
                      <ServiceMembershipDetail data={data?.membershipDetails} />
                    )
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: '18px',
                      }}
                    >
                      No Data Found
                    </div>
                  )}
                </DialogContent>
                <DialogActions sx={{ margin: '0 12px', gap: '10px' }}>
                  {dialogLoading ? (
                    <ShowLoader value={dialogLoading} />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        // fullWidth
                        color="primary"
                        target="_blank"
                        sx={{
                          background: '#6174DD',
                        }}
                        onClick={handleCloseDialogues}
                      >
                        Cancel
                      </Button>
                      {data?.membershipDetails?.membershipType ? (
                        <Button
                          variant="contained"
                          // fullWidth
                          target="_blank"
                          sx={{
                            background: '#25d366',
                            '&:hover': { background: '#25d366' },
                          }}
                          onClick={handleSendMembershipDetails}
                        >
                          <WhatsAppIcon sx={{ margin: '0 5px' }} />
                          WhatsApp
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          // fullWidth
                          color="primary"
                          target="_blank"
                          sx={{
                            background: '#6174DD',
                          }}
                          onClick={() =>
                            navigate('/memberships', {
                              state: { clientNumber: data?.clientNumber },
                            })
                          }
                        >
                          Buy
                        </Button>
                      )}
                    </>
                  )}
                </DialogActions>
              </Dialog>

              {/* ---------------------- Package Details Dialog ---------------- */}
              <Dialog open={openPackageDialog} onClose={handleClosePackageDialog} maxWidth="md">
                <DialogTitle>Package Details</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {data?.packageDetails?.length > 0 ? (
                      data.packageDetails.map((packageDetail, packageIndex) => (
                        <div
                          key={packageIndex}
                          style={{ padding: '10px', margin: '10px 0', border: '1px solid #ddd', borderRadius: '5px' }}
                        >
                          <p>
                            <strong>Package Name:</strong> {packageDetail.packageName || 'N/A'}
                          </p>
                          <p>
                            <strong>Price:</strong> {packageDetail.price || 'N/A'}
                          </p>
                          <p>
                            <strong>Services:</strong>
                          </p>
                          {packageDetail.services?.length > 0 ? (
                            <ul style={{ paddingLeft: '20px', marginTop: '5px' }}>
                              {packageDetail.services.map((service, serviceIndex) => (
                                <li key={serviceIndex} style={{ listStyleType: 'circle' }}>
                                  {service.serviceName}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p> your have taken all your services</p>
                          )}
                          <p>
                            <strong>Validity:</strong> {packageDetail.validity || 'N/A'}
                          </p>
                          <hr style={{ margin: '20px 0', borderColor: '#eee' }} />
                        </div>
                      ))
                    ) : (
                      <p>No packages available</p>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ margin: '0 12px', gap: '10px' }}>
                  {dialogLoading ? (
                    <ShowLoader value={dialogLoading} />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ background: '#6174DD' }}
                        onClick={handleClosePackageDialog}
                      >
                        Cancel
                      </Button>
                      {data?.packageDetails?.length > 0 ? (
                        <Button
                          variant="contained"
                          target="_blank"
                          sx={{ background: '#25d366', '&:hover': { background: '#25d366' } }}
                          onClick={handleSendPackageDetails}
                          disabled
                        >
                          <WhatsAppIcon sx={{ margin: '0 5px' }} />
                          WhatsApp
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ background: '#6174DD' }}
                          onClick={() =>
                            navigate('/memberships',{
                              state: { clientNumber: data?.clientNumber },
                            } )
                          }
                        >
                          Buy Package
                        </Button>
                      )}
                    </>
                  )}
                </DialogActions>
              </Dialog>


              <div className="client-container">
                <div className="client-header">
                  <div className="profile-img"></div>
                  <div>
                    <p className="client-text">Full Name</p>
                    <h6 className="client-bold-title">{data?.clientName}</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="client-body">
                      <p className="client-text">Email</p>
                      <h6 className="client-title">{data?.clientEmail}</h6>
                    </div>
                    <div className="client-body">
                      <p className="client-text">Birthday</p>
                      <h6 className="client-title">{data?.birthday}</h6>
                    </div>
                    <div className="client-body">
                      <p className="client-text">Anniversary</p>
                      <h6 className="client-title">{data?.anniversary}</h6>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="client-body">
                      <p className="client-text">Mobile</p>
                      <h6 className="client-title">
                        {!data?.clientNumber || typeof branchData?.isMasked !== 'boolean'
                          ? 'loading...'
                          : maskData(data?.clientNumber, branchData?.isMasked)}
                      </h6>
                    </div>
                    <div className="client-body">
                      <p className="client-text">Address</p>
                      <h6 className="client-title">{data?.clientAddress?.address}</h6>
                    </div>
                    <div className="client-body">
                      <p className="client-text">Total Bill Amount</p>
                      <h6 className="client-title">{data?.totalBillsAmount}</h6>
                    </div>
                  </div>
                </div>

                <table className="table-container mt-5">
                  <thead className="table-thead">
                    <tr>
                      <th style={{ width: '20%' }}>No</th>
                      {[
                        'Date of Bill',
                        'Services',
                        'Service Provider',
                        'Products',
                        'Total',
                        'Paid',
                        'Pending',
                        'Bill',
                      ].map((h, index) => {
                        return (
                          <th key={index} style={{ width: '100%' }}>
                            {h}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="table-tbody">
                    {billData !== undefined ? (
                      billData?.map((bill, index) => {
                        return (
                          <tr key={bill?._id}>
                            <td style={{ width: '20%', fontWeight: 'bold' }}>{index + 1}</td>
                            <td style={{ width: '100%' }}>{bill?.dateOfBilling}</td>
                            <td style={{ width: '100%' }}>
                              {bill?.services
                                ?.map((service) => service?.service?.serviceName)
                                .join(',  ')}
                            </td>
                            <td style={{ width: '100%' }}>
                              {bill?.services
                                ?.map((service) => service?.serviceProvider?.name)
                                .join(',  ')}
                            </td>
                            <td style={{ width: '100%' }}>
                              {bill?.products?.map((product) => product?.productName).join(',  ')}
                            </td>

                            <td style={{ width: '100%' }}>{bill?.amountPayable}</td>
                            <td style={{ width: '100%' }}>
                              {bill?.amountPaid.reduce(
                                (acc, payment) => acc + Number(payment.amount),
                                0,
                              )}
                            </td>
                            <td style={{ width: '100%' }}>{bill?.paidDues}</td>
                            <td style={{ width: '100%' }}>
                              <button
                                style={{
                                  backgroundColor: '#6174DD',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  color: '#fff',
                                  border: 'none',
                                  borderRadius: '8px',
                                  padding: '5px 20px',
                                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                  transition: 'background-color 0.3s ease-in-out',
                                }}
                                onClick={() => navigate(`/billing/${bill._id}`)}
                              >
                                Bill
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5}> Data Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            'No data found'
          )}
        </DashboardCard2>
      </PageContainer>
    </>
  );
}

export default ClientDetails;
