import { baseUrl, headers, jwtHeaders } from '../common/data';

export const fetchAllEquiry = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/clientenquiry/getall/${branchId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
    },
  );
  const resData = await response.json();
  return resData;
};

export const createClientEnquiry = async (data) => {
  const response = await fetch(`${baseUrl}/api/clientenquiry/create`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: jwtHeaders(),
  });
  const resData = await response.json();
  return resData;
};

export const updateClientEnquiry = async (id, data, branchId) => {
  const response = await fetch(`${baseUrl}/api/clientenquiry/update/${id}${branchId ? branchId : ''}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: jwtHeaders()
  })
  const resData = await response.json();
  return resData;
}

// export const deleteClientEnquiry = async (enquiryId,branchId) => {
//   const response = await fetch(`${baseUrl}/api/clientenquiry/delete/${enquiryId}${branchId ? branchId : ''}`, {
//     method: 'DELETE',
//     headers:jwtHeaders(),
//   });
//   const resData = await response.json();
//   return resData;
// };
export const deleteClientEnquiry = async (enquiryId, branchId) => {
  const response = await fetch(`${baseUrl}/api/clientenquiry/delete/${branchId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...jwtHeaders(),
      },
      body: JSON.stringify({
        ids: [enquiryId],
        status: false,
      }),
    });
  const resData = await response.json();
  return resData;
};
