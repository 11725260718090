//new filter code
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import Appointments from './Appointments';
import { MenuItem, Select, InputLabel, Box, Button, Grid, Pagination, IconButton } from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import SearchImg from '../../assets/search.svg';
import BigFilter from 'src/components/inputs/BigFilter';
import ClearIcon from '@mui/icons-material/Close';

const filterMenu = [
  { value: 'Walk In', label: 'Walk In' },
  { value: 'Existing', label: 'Existing' },
  { value: 'Phone Call', label: 'Phone Call' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Web Appointment', label: 'Web Appointment' },
];

const filterStatusMenu = [
  { value: 'RESERVED', label: 'RESERVED' },
  { value: 'COMPLETE', label: 'COMPLETE' },
];

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

const AllAppointments = () => {
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState([]);
  const [sourceOfAppointment, setSourceOfAppointment] = useState([]);
  const [appointmentStatus, setAppointmentStatus] = useState([]);
  const [clear, setClear] = useState(false);

  const [filterData, setFilterData] = useState(null);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const { fetchAllAppointments, activeBranchId } = useMain();

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setSourceOfAppointment([]);
    setAppointmentStatus([]);
    setGenderBy([]);
    if (clear) {
      getData('clear');
      setClear(false);
    }
  };

  const onApplyHandler = () => {
    if (search) {
      getData();
      setClear(true);
    }
  };

  const getData = async (key) => {
    let queryParams = `?page=${page}&limit=${limit}${sourceOfAppointment.length ? `&sourceOfAppointment=${sourceOfAppointment.join(',')}` : ''
      }${appointmentStatus.length ? `&appointmentStatus=${appointmentStatus.join(',')}` : ''}${genderBy.length ? `&gender=${genderBy.join(',')}` : ''
      }${search ? `&search=${search}` : ''}`;

    if (key) queryParams = `?page=${page}&limit=${limit}`;
    try {
      setShowLoader(true);
      const res = await fetchAllAppointments(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      setClear(true);
      getData();
    }
    return () => {
      setData([]);
    };
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters()
  }, [limit, page, sourceOfAppointment, appointmentStatus, genderBy, data, search]);

  const applyFilters = () => {
    let filteredData = data.filter(item => {
      let isGenderMatch = true;
      let isSourceMatch = true;
      let isStatusMatch = true;
      let isNameMatch = true;

      if (genderBy.length) {
        isGenderMatch = genderBy.includes(item.gender);
      }

      if (sourceOfAppointment.length) {
        isSourceMatch = sourceOfAppointment.includes(item.sourceOfAppointment);
      }

      if (appointmentStatus.length) {
        isStatusMatch = appointmentStatus.includes(item.appointmentStatus);
      }

      if (search) {
        isNameMatch = item.clientName.toLowerCase().includes(search.toLowerCase()) ||
          item.clientNumber.includes(search);
      }

      return isGenderMatch && isSourceMatch && isStatusMatch && isNameMatch;
    });

    // Applying pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  return (
    <PageContainer title="Manage Appointments" description="this contains all Appointments">
      <DashboardCard2 title="Manage Appointments">
        <div style={{
          display: 'flex',
          flexWrap: 'wrap', // Allow items to wrap to the next line
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '1.7rem'
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '1rem' }}>
            <InputLabel>Show per page</InputLabel>
            <Select
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
              }}
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 20%', maxWidth: '280px', marginLeft: '36rem' }}>
            <BigFilter
              options={[
                { title: 'Source of Appointment', items: filterMenu },
                { title: 'Appointment Status', items: filterStatusMenu },
                { title: 'Gender', items: filterGenderWise }
              ]}
              value={[...sourceOfAppointment, ...appointmentStatus, ...genderBy]}
              onChange={(selectedValues) => {
                setSourceOfAppointment(selectedValues.filter(val => filterMenu.some(opt => opt.value === val)));
                setAppointmentStatus(selectedValues.filter(val => filterStatusMenu.some(opt => opt.value === val)));
                setGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
              }}
              placeholder="Filters"
              style={{ maxWidth: '200px' }} // Set max width for the filter
            />
          </div>

          <div onClick={() => document.getElementById('search-input').focus()}>
            <InputLabel style={{ marginRight: '0.5rem' }}>Search</InputLabel>
            <div className="search-input" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <img src={SearchImg} alt="" style={{ marginRight: '0.5rem' }} />
              <input
                id="search-input" // Add an ID to the input field
                type="text"
                placeholder=""
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ width: '100%' }} // Ensure full width
              />
              {search && (
                <IconButton
                  onClick={() => {
                    setSearch('');
                    setClear(false);
                  }}
                  style={{ padding: 1 }}
                >
                  <ClearIcon style={{
                    color: '#000',
                    marginRight: '-0.2rem',
                    marginTop: '0.1rem',
                    fontSize: '1.1rem'
                  }} />
                </IconButton>
              )}
            </div>
          </div>

          {/* <div style={{ marginTop: '1.8rem', marginRight: '1.2rem' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={clearDataHandler}
              style={{ width: '124%', backgroundColor: '#ff4d4d', color: 'white' }} // Adjust button width if necessary
            >
              Clear
            </Button>
          </div> */}
        </div>
        {showLoader ? (
          <ShowLoader />
        ) : filterData && filterData.length > 0 ? (
          <>
            <Box style={{ paddingTop: "-6rem" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Appointments data={filterData} getData={getData} setData={setData} />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          'No data found'
        )}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={filterData && filterData?.length < limit ? page : page + 1}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer >
  );
};

export default AllAppointments;

