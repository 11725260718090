import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid as MuiGrid,
  Pagination,
  Grid,
  IconButton,
} from '@mui/material';
import TableCells from 'src/components/Tables/TableCells';
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import SearchImg from '../../assets/search.svg';
import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
import BigFilter from 'src/components/inputs/BigFilter';
import { ClearIcon } from '@mui/x-date-pickers-pro';
import { MdDelete } from "react-icons/md";

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

const packageType = [
  { value: 'Package', label: 'Package Bills' },
  { value: 'Membership', label: 'Membership Bills' },
];


export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const PackageAllBill = () => {
  const [packagedata, setPackageData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState([]);
  const [selectedByPackage, setSelectedByPackage] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [filteredDataCount, setFilteredDataCount] = useState(0);
  const [packagePageTotals, setPackagePageTotals] = useState({ totalAmount: 0, amountPaid: 0, paidDues: 0 });

  const { activeBranchId, setShowMessage, fetchPackageBills, branchData, deletePackagebill } =
    useMain();
  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDateRangeSelected = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    setIsModalOpen(false);
    const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''
      }${start ? `&startDate=${start}` : ''}${end ? `&endDate=${end}` : ''}`;
    getPackageBillsData(queryParams);
    setClear(true);
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setGenderBy('');
    setEndDate('');
    setStartDate('');
    setSelectedByPackage('')
    if (clear) {
      const queryParams = `?page=1&limit=1000`;
      getPackageBillsData(queryParams);
      setClear(false);
    }
  };

  const onApplyHandler = () => {
    const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''
      }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;
    getPackageBillsData(queryParams);
    setClear(true);
  };

  useEffect(() => {
    if (activeBranchId) {
      const queryParams = `?limit=1000`

      getPackageBillsData(queryParams);
      setClear(true);
    }
    return () => {
      setPackageData([]);
    };
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters(packagedata, limit, selectedByPackage, genderBy, page, search, startDate, endDate);
  }, [packagedata, limit, genderBy, page, search, startDate, endDate]);

  useEffect(() => {
    const totals = calculatePageTotals(filterData);
    setPackagePageTotals(totals);
  }, [filterData]);

  const calculatePageTotals = (currentPageData) => {
    const totalAmount = currentPageData.reduce((acc, item) => acc + (item.amountPayable || 0), 0);

    const amountPaid = currentPageData.reduce((acc, item) => {
      if (Array.isArray(item.amountPaid)) {
        const totalPaid = item.amountPaid.reduce((sum, payment) => sum + (parseFloat(payment.amount) || 0), 0);
        return acc + totalPaid;
      }
      return acc;
    }, 0);

    const paidDues = currentPageData.reduce((acc, item) => acc + (item.paidDues || 0), 0);

    // Ensure paidDues is not NaN
    return { totalAmount, amountPaid, paidDues: isNaN(paidDues) ? 0 : paidDues };
  };

  const getPackageBillsData = async (queryParams) => {
    try {
      setShowLoader(true);
      const res = await fetchPackageBills(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        setPackageData(res?.data || []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };
  console.log(packagedata, "dataa")
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  // const applyFilters = (data, limit, selectedByPackage, gender, page, search, startDate, endDate) => {
  //   let filteredData = data.filter((item) => {
  //     let isGenderMatch = true;
  //     let isNameMatch = true;
  //     let isPackageType = true;
  //     // if (gender) {
  //     //   isGenderMatch = item.gender === gender;
  //     // }

  //     if (selectedByPackage.length) {
  //       isPackageType = selectedByPackage.includes(item.billType)
  //     }

  //     if (gender.length) {
  //       isGenderMatch = genderBy.includes(item.gender);
  //     }

  //     if (search) {
  //       isNameMatch =
  //         item.clientName.toLowerCase().includes(search.toLowerCase()) ||
  //         item.clientNumber.includes(search);
  //     }

  //     if (startDate && endDate) {
  //       const itemDate = new Date(item.dateOfBilling);
  //       const start = new Date(startDate);
  //       const end = new Date(endDate);
  //       isNameMatch = isNameMatch && itemDate >= start && itemDate <= end;
  //     }

  //     return isGenderMatch && isNameMatch && isPackageType;
  //   });

  //   // Applying pagination
  //   const startIndex = (page - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   filteredData = filteredData.slice(startIndex, endIndex);
  //   setFilterData(filteredData);
  // };
  const applyFilters = (data, limit, selectedByPackage, gender, page, search, startDate, endDate) => {
    let filteredData = data.filter((item) => {
      let isGenderMatch = true;
      let isNameMatch = true;
      let isPackageType = true;

      if (selectedByPackage.length) {
        isPackageType = selectedByPackage.includes(item.billType);
      }

      if (gender.length) {
        isGenderMatch = genderBy.includes(item.gender);
      }

      if (search) {
        isNameMatch =
          item.clientName.toLowerCase().includes(search.toLowerCase()) ||
          item.clientNumber.includes(search);
      }

      if (startDate && endDate) {
        const itemDate = new Date(item.dateOfBilling);
        const start = new Date(startDate);
        const end = new Date(endDate);
        isNameMatch = isNameMatch && itemDate >= start && itemDate <= end;
      }

      return isGenderMatch && isNameMatch && isPackageType;
    });

    // Save the count of filtered data for pagination
    setFilteredDataCount(filteredData.length);

    // Applying pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  const handlePageChange = (e, value) => {
    setPage(value);
    // setPage(1);
  };
  const handleDeleteField = (id) => {
    setDeleteId(id); // Set the ID to be deleted
    setOpenDeleteDialog(true); // Open confirmation dialog
  }

  const confirmDelete = async () => {
    setDeleteLoading(true); // Start loading
    try {
      const res = await deletePackagebill(deleteId, activeBranchId);
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Bill deleted successfully',
          messageType: 'success',
        });

        // Update filterData by removing the deleted bill
        setFilterData((prevData) => prevData.filter((item) => item._id !== deleteId));
      } else {
        setShowMessage({
          message: res.message || 'Bill not deleted',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Something went wrong',
        messageType: 'error',
      });
    } finally {
      setOpenDeleteDialog(false); // Close dialog after operation
      setDeleteId(null); // Reset delete ID
      setDeleteLoading(false); // Stop loading
    }
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };
console.log(packagedata,'packagedata')
  return (
    <PageContainer title="All Package Bills" description="this contains all products">
      <DashboardCard2 title="All Package Bills">
        {/* <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            // gap: '18rem',
            alignItems: 'center',
            marginBottom: '1.5rem'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              flex: '1 1 300px',
              justifyContent: 'space-between'
            }}>
              <div style={{ flex: '1 1 150px', maxWidth: '500px' }}>
                <InputLabel>Show per page</InputLabel>
                <Select
                  style={{
                    borderRadius: '7px',
                    border: '0.5px solid #6174DD',
                    padding: '4px 12px',
                    background: '#FFF',
                    height: '40px',
                  }}
                  value={limit}
                  onChange={handleLimitChange}
                >
                  {[10, 20, 50].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ flex: '1 1 150px' }}>
                <Button
                  variant="outlined"
                  sx={{
                    color: 'black',
                    height: '40px',
                    width: startDate && endDate ? '100%' : '60%', // Use full width when dates are selected
                    // marginTop: '0.4rem',
                    marginBottom: '0.3rem',
                    marginLeft: startDate && endDate ? '-19rem' : '-19rem', // Adjust margin based on date selection
                    display: 'flex',
                    justifyContent: startDate && endDate ? 'space-between' : 'center',
                    padding: '0 8px',
                    whiteSpace: 'nowrap', // Prevent text wrapping
                    overflow: 'hidden', // Hide overflow
                    textOverflow: 'ellipsis', // Add ellipsis for overflow text
                    // width: '226px',
                    marginTop: '0.5rem'
                  }}
                  onClick={openModal}
                >
                  {startDate && endDate ? (
                    <>
                      <span>{`${startDate} to ${endDate}`}</span>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setStartDate('');
                          setEndDate('');
                          setClear(false);
                          onApplyHandler(); // Make sure to apply filters after clearing
                        }}
                        sx={{
                          padding: 0,
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <ClearIcon style={{ color: '#000', height: '1rem' }} />
                      </IconButton>
                    </>
                  ) : (
                    'Choose Date'
                  )}
                </Button>
              </div>
            </div>
          </div> */}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              marginBottom: '1.5rem',
            }}
          >
            {/* Left Side: Show per page and Choose Date */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center', // Center align the items vertically
                flex: '1 1 300px',
              }}
            >
              <div style={{ flex: '1 1 150px', maxWidth: '500px' }}>
                <InputLabel>Show per page</InputLabel>
                <Select
                  style={{
                    borderRadius: '7px',
                    border: '0.5px solid #6174DD',
                    padding: '4px 12px',
                    background: '#FFF',
                    height: '40px',
                  }}
                  value={limit}
                  onChange={handleLimitChange}
                >
                  {[10, 20, 50].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div style={{
                flex: '1 1 150px', marginLeft: '-16rem',
                marginTop: '1.9rem'
              }}>
                <Button
                  variant="outlined"
                  sx={{
                    color: 'black',
                    height: '40px',
                    width: startDate && endDate ? '60%' : '40%', // Use full width when dates are selected
                    display: 'flex',
                    justifyContent: startDate && endDate ? 'space-between' : 'center',
                    padding: '0 8px',
                    whiteSpace: 'nowrap', // Prevent text wrapping
                    overflow: 'hidden', // Hide overflow
                    textOverflow: 'ellipsis', // Add ellipsis for overflow text
                  }}
                  onClick={openModal}
                >
                  {startDate && endDate ? (
                    <>
                      <span>{`${startDate} to ${endDate}`}</span>
                      {/* <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setStartDate('');
                          setEndDate('');
                          setClear(false);
                          onApplyHandler(); // Make sure to apply filters after clearing
                        }}
                        sx={{
                          padding: 0,
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <ClearIcon style={{ color: '#000', height: '1rem' }} />
                      </IconButton> */}
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setStartDate('');
                          setEndDate('');
                          setClear(false);
                          onApplyHandler(); // Make sure to apply filters after clearing
                          // Fetch all data without date filters
                          // const queryParams = `?page=1&limit=${limit}`;
                          getPackageBillsData();
                        }}
                        sx={{
                          padding: 0,
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <ClearIcon style={{ color: '#000', height: '1rem' }} />
                      </IconButton>

                    </>
                  ) : (
                    'Choose Date'
                  )}
                </Button>
              </div>
            </div>
          </div>


          {/* Right Side: Filters and Search */}
          <div style={{
            display: 'flex',
            // flex: '5 1 500px',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1.4rem',
            marginLeft: '15rem'
          }}>
            <div style={{ width: '60%', marginRight: '-4rem' }}>
              <BigFilter
                options={[
                  { title: 'Gender', items: filterGenderWise },
                  { title: 'Package Type', items: packageType }
                ]}
                value={[...genderBy, ...selectedByPackage]}
                onChange={(selectedValues) => {
                  setGenderBy(selectedValues.filter(val => filterGenderWise.some(opt => opt.value === val)));
                  setSelectedByPackage(selectedValues.filter(val => packageType.some(opt => opt.value === val)));
                }}
                placeholder="Filters"
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8rem' }} onClick={() => document.getElementById('search-input').focus()}>
              <InputLabel style={{ display: 'flex', flexDirection: 'column' }}>Search</InputLabel>
              <div className="search-input" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={SearchImg} alt="search icon" style={{ marginRight: '8px' }} />
                <input
                  id="search-input"
                  type="text"
                  // placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ border: 'none', outline: 'none', flex: 1 }}
                />
                {search && (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                      setClear(false);
                    }}
                    style={{ padding: 1 }}
                  >
                    <ClearIcon style={{
                      // color: '#ff4d4d',
                      color: '#000',
                      // color: '#A2A3AE',
                      marginRight: '-0.2rem', marginTop: '0.1rem',
                      fontSize: '1.1rem'
                    }} />
                  </IconButton>
                )}
              </div>
            </div>

            {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '1.5rem', width: '5.5rem' }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  width: '100%',
                  background: '#ff4d4d',
                  '&:hover': { background: '#ff6666' },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </div> */}
          </div>
        </div>

        {showLoader ? (
          <ShowLoader />
        ) : filterData.length > 0 ? (
          <Box>
            <MuiGrid container spacing={3}>
              <MuiGrid item xs={12}>
                <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
                  <table className="table-container mt-3">
                    <thead className="table-thead">
                      <tr>
                        {[
                          'Date of Bill',
                          'Client Name',
                          'Contact',
                          'Gender',
                          'Total',
                          'Paid',
                          'Pending',
                          'Mode of Payment',
                          'Actions',
                        ].map((h, index) => (
                          <th key={index} style={{ width: '100%' }}>
                            {h}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="table-tbody">
                      {filterData.map((item) => {
                        const originalDate = item?.dateOfBilling;

                        // Convert "YYYY-MM-DD" to "DD-MM-YYYY"
                        let formattedBillDate = '';
                        if (originalDate) {
                          const [year, month, day] = originalDate.split('-');
                          formattedBillDate = `${day}-${month}-${year}`;
                        }
                        return (
                          <tr key={item._id}>
                            <td style={{ width: '100%' }}>{formattedBillDate}</td>
                            <td style={{ width: '100%' }}>{item?.clientName}</td>
                            <td style={{ width: '100%' }}>
                              {maskData(item?.clientNumber, branchData?.isMasked)}
                            </td>
                            <td style={{ width: '100%' }}>{item?.gender}</td>
                            <td style={{ width: '100%' }}>{item?.amountPayable}</td>
                            <td style={{ width: '100%' }}>
                              {item?.amountPaid.reduce(
                                (acc, payment) => acc + Number(payment.amount),
                                0,
                              )}
                            </td>
                            <td style={{ width: '100%' }}>{"0"}</td>
                            <td style={{ width: '100%' }}>
                              {item?.amountPaid?.map(({ paymentType }) => paymentType).join(',  ')}
                            </td>
                            {/* <td style={{ width: '100%' }}>
                              <TableCells
                                type="button"
                                field={item?._id}
                                handleDeleteField={handleDeleteField}
                              />
                            </td> */}
                            <td style={{
                              width: '100%',
                            }}>
                              <IconButton onClick={() => handleDeleteField(item._id)} color="error" title="Delete" style={{ margin: '-6px' }}>
                                <MdDelete color='#FF4D4D' style={{ fontSize: '1.2rem', marginTop: '1px' }} />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                    <DialogContent>
                      Are you sure you want to delete this Bill?
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
                      <Button onClick={confirmDelete} color="secondary">Delete</Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </MuiGrid>
            </MuiGrid>
          </Box>
        ) : (
          'No data found'
        )}

        <Box display="flex" justifyContent="flex-end">
          <Pagination
            count={Math.ceil(filteredDataCount / limit)}
            page={page}
            color="primary"
            onChange={handlePageChange}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          style={{
            width: '100%',
            marginLeft: '0.5rem',
            marginTop: '1rem',
            padding: '1rem',
            // backgroundColor: '#D6D6C8', // Light cream color
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
            borderRadius: '8px' // Rounded corners for a softer look
          }}
        >
          <Typography variant="h6" fontWeight="600" gutterBottom>
            Amount as per Page
          </Typography>

          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" fontWeight="600">
              <strong>Total Amount:</strong> ₹ {packagePageTotals.totalAmount}
            </Typography>
            <Typography variant="subtitle1" fontWeight="600">
              <strong>Total Paid:</strong> ₹ {packagePageTotals.amountPaid}
            </Typography>
            <Typography variant="subtitle1" fontWeight="600">
              <strong>Total Pending:</strong> ₹ {packagePageTotals.paidDues}
            </Typography>
          </Box>
        </Box>

        <DateRangeInputs
          isOpen={isModalOpen}
          onClose={closeModal}
          onDateRangeSelected={handleDateRangeSelected}
          selectedEndDate={endDate}
          selectedStartDate={startDate}
        />
      </DashboardCard2>
    </PageContainer >
  );
};

export default PackageAllBill;
