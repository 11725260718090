// import logo2 from 'src/assets/images/logos/logo.jpeg';
// // import logo2 from 'src/assets/images/logos/YourSalon_Logo-03.png';
// import { styled, Box } from '@mui/system'; // Use '@mui/system' instead of '@mui/material'

// const StyledLinkBox = styled(Box)(() => ({
//   height: '80px',
//   width: '100px',
//   overflow: 'hidden',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'flex-end',
//   alignItems: 'center',
//   textDecoration: 'none',
//   marginLeft:'2.5rem'
// }));

// const Logo = () => {
//   return (
//     <StyledLinkBox>
//       <img src={logo2} alt="logo" height={60} width={60} style={{ borderRadius: '8px' }} />
//     </StyledLinkBox>
//   );
// };

// export default Logo;


// new logo added

// import logo2 from 'src/assets/images/logos/logo.jpeg';
import logo2 from 'src/assets/images/logos/YourSalon_Logo-03.png';
// import logo2 from 'src/assets/images/logos/YourSalon_Logo-04.png';
import { styled, Box } from '@mui/system'; // Use '@mui/system' instead of '@mui/material'
import { Link } from 'react-router-dom';

const StyledLinkBox = styled(Box)(() => ({
  height: '60px',
  // height: '65px',
  width: '210px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  textDecoration: 'none',
  marginLeft: '-1.5rem',
  marginTop: '1.5rem',
  padding: '0 7rem',
  backgroundColor: '#fff',
  boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
  cursor: 'pointer',
}));

const Logo = () => {
  return (
    <Link to="/" style={{textDecoration:'none'}}>
      <StyledLinkBox>
        {/* <img src={logo2} alt="logo" height={38} width={140} style={{ width: 'inherit', borderRadius: '0px', marginBottom: '0.5rem' }} /> */}
        <img src={logo2} alt="logo" height={40} width={140} style={{ width: 'inherit', borderRadius: '0px', marginBottom: '0.5rem' }} />
      </StyledLinkBox>
    </Link>
  );
};

export default Logo;