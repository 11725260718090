//pagination correction
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import Products from './Products';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from '@mui/material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import * as XLSX from 'xlsx';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import Book1Product from '../../assets/excel/Book1Product.xlsx';
import SearchImg from '../../assets/search.svg';
import { ClearIcon } from '@mui/x-date-pickers-pro';

const AllProducts = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);

  const { fetchAllProducts, activeBranchId, createManyProduct, setShowMessage, deleteManyProduct, deleteProduct } = useMain();

  useEffect(() => {
    if (activeBranchId) {
      getData();
    }
  }, [activeBranchId]);

  useEffect(() => {
    applyFilters();
  }, [data, limit, page, search]);

  const getData = async () => {
    try {
      setShowLoader(true);
      const res = await fetchAllProducts(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      } else {
        setShowMessage({ message: res.message || 'Failed to fetch data', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setShowLoader(false);
    }
  };

  const applyFilters = () => {
    const filtered = data.filter(item =>
      item.productName.toLowerCase().includes(search.toLowerCase())
    );

    setFilteredData(filtered);

    // Reset page to 1 if filtered data length is less than current page
    if (filtered.length < (page - 1) * limit) {
      setPage(1);
    }
  };

  const handlePagination = () => {
    const startIndex = (page - 1) * limit;
    const paginatedData = filteredData.slice(startIndex, startIndex + limit);
    return paginatedData;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);
        setUploadedData(jsonData);
      };
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  const downloadExcelTemplate = () => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = Book1Product;
    a.download = 'product_template.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    closeModal();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!uploadedData) return setShowMessage({ message: 'Please Upload valid file', messageType: 'error' });
    try {
      setShowLoader(true);
      const res = await createManyProduct(activeBranchId, { bulkData: uploadedData });
      if (res?.statusCode === 200) {
        setShowMessage({ message: res.message || 'Uploaded Bulk Data Successfully', messageType: 'success' });
        setUploadedData(null);
        getData();
      } else {
        setShowMessage({ message: res.message || 'Bulk Data not uploaded', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
    } finally {
      setShowLoader(false);
      closeModal();
    }
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    // if (clear) {
    //   getData();
    //   setClear(false);
    // }
  };

  const onApplyHandler = () => {
    if (search) {
      applyFilters();
      setClear(true);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const handleBulkDelete = () => {
    setOpenDialogue(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  // const submitBulkDelete = async () => {
  //   try {
  //     setDeleteLoading(true);
  //     const res = await deleteManyProduct({ ids: checkedProducts }, `?branchId=${activeBranchId}`);
  //     if (res.statusCode === 200) {
  //       setShowMessage({ message: res.message || 'Successfully deleted Products', messageType: 'success' });
  //       setCheckedProducts([]);
  //       getData();
  //     } else {
  //       setShowMessage({ message: res.message || 'Error occurred deleting products', messageType: 'error' });
  //     }
  //   } catch (error) {
  //     setShowMessage({ message: error.message || 'Something Went Wrong', messageType: 'error' });
  //   } finally {
  //     setDeleteLoading(false);
  //     handleCloseDialogue();
  //   }
  // };

  const submitBulkDelete = async () => {
    try {
      setDeleteLoading(true);
      const productIds = checkedProducts.map(product => product?._id);

      const payload = {
        ids: productIds, 
      };
  
      const response = await deleteManyProduct(payload, activeBranchId);
  
      if (response.statusCode === 200) {
        setShowMessage({ message: 'Successfully deleted products', messageType: 'success' });
        setCheckedProducts([]); 
        getData();  
      } else {
        setShowMessage({ message: response.message || 'Error occurred while deleting products', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setDeleteLoading(false);
      handleCloseDialogue();
    }
  };
  

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <PageContainer title="Manage Products" description="this contains all Products">
      <DashboardCard2 title="Manage Products">
        <div style={{ display: 'grid', gridTemplateColumns: '2fr auto' }}>
          <div style={{ marginBottom: '1.7rem' }}>
            <InputLabel>Show per page</InputLabel>
            <Select
              style={{
                borderRadius: '7px',
                border: '0.5px solid #6174DD',
                padding: '4px 12px',
                background: '#FFF',
                height: '40px',
                width: '10%',
              }}
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'center', gap: '1.2rem', width: '76%' }}>
            <div style={{ marginBottom: '1.6rem', marginRight: '-4.8rem' }} onClick={() => document.getElementById('search-input').focus()}>
              <InputLabel>Search</InputLabel>
              <div className="search-input" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={SearchImg} alt="" />
                <input
                  id="search-input"
                  type="text"
                  placeholder=""
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ marginLeft: '0.5rem' }}
                />
                {search && (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                      setClear(false);
                    }}
                    style={{ padding: 1 }}
                  >
                    <ClearIcon style={{
                      // color: '#ff4d4d',
                      color: '#000',
                      // color: '#A2A3AE',
                      marginRight: '-0.2rem', marginTop: '0.1rem',
                      fontSize: '1.1rem'
                    }} />
                  </IconButton>
                )}
              </div>
            </div>

            {/* <div style={{ marginRight: '1rem' }}>
              <Button
                variant="contained"
                color="primary"
                target="_blank"
                sx={{
                  height: '40px',
                  // width: '100%',
                  width: '56%',
                  marginLeft: '5rem',
                  background: '#ff4d4d',
                  '&:hover': {
                    background: '#ff6666',
                  },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </div> */}
          </div>
        </div>

        <Grid container justifyContent="flex-end">
          <Grid item justifyContent="flex-end">
            {checkedProducts.length ? (
              <Button
                variant="contained"
                color="primary"
                target="_blank"
                sx={{
                  background: '#ff4d4d',
                  '&:hover': {
                    background: '#ff6666',
                  },
                }}
                onClick={handleBulkDelete}
              >
                Delete
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                target="_blank"
                sx={{
                  background: '#6174DD',
                }}
                onClick={openModal}
              >
                Product Upload
              </Button>
            )}
          </Grid>
        </Grid>


        {/* Modal Dialog */}
        <Dialog open={isModalOpen} onClose={closeModal}>
          <DialogTitle>Create Many Products</DialogTitle>
          <form onSubmit={handleFormSubmit}>
            <Box p={2}>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
              />
            </Box>
            {showLoader && <ShowLoader value={showLoader} />}
            <Box p={2}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ ml: 2 }}
                onClick={downloadExcelTemplate}
              >
                <ArrowCircleDownIcon sx={{ margin: '0px 2px 0px 0px' }} />
                Sample Download
              </Button>
            </Box>
          </form>
        </Dialog>

        {/* Delete Dialog */}
        <Dialog open={openDialogue} onClose={handleCloseDialogue}>
          <DialogTitle>Delete item</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this data?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogue}>Cancel</Button>
            <Button onClick={submitBulkDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {showLoader ? (
          <ShowLoader />
        ) : filteredData.length > 0 ? (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Products
                  // data={filteredData}
                  data={handlePagination()}
                  setData={setData}
                  getData={getData}
                  checkedProducts={checkedProducts}
                  setCheckedProducts={setCheckedProducts}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          'No data found'
        )}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={Math.ceil(filteredData.length / limit) || 1}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllProducts;
