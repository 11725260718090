import React, { useState, useEffect } from 'react';
import { FormControl, Select, MenuItem, Button } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import StaffBarGraph from './StaffBarGraph';
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const filterOptions = [
    { label: 'Current Year', value: 'Current Year' },
    { label: 'Current Month', value: 'Current Month' },
    { label: 'Current Week (Sun-Sat)', value: 'Current Week' },
    { label: 'Today', value: 'Today' },
    { label: 'Custom Date', value: 'customDate' },
];

const WorkAnalysis = () => {
    const [filterBy, setFilterBy] = useState('Today');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [staffGraphData, setStaffGraphData] = useState({ categories: [], series: [], services: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);  // Track the initial load state

    const { activeBranchId, staffWorkAnalysis } = useMain();
    const navigate = useNavigate();

    useEffect(() => {
        if (activeBranchId && firstLoad) {
            getStaffGraphData('Today');
            setFirstLoad(false); 
        }
    }, [activeBranchId, firstLoad]);

    const getStaffGraphData = async (filterValue) => {
        setIsLoading(true);
        const queryParams = `?filterBy=${filterValue || filterBy}${from ? `&startDate=${from}` : ''}${to ? `&endDate=${to}` : ''}`;
        try {
            const res = await staffWorkAnalysis(activeBranchId, queryParams);
            if (res.statusCode === 200) {
                const staff = res?.data?.staff || [];
                const serviceData = res?.data?.serviceData || [];

                const staffNames = staff.map(staffMember => staffMember.name);
                const totalServiceCounts = staff.map(staffMember => {
                    const service = serviceData.find(s => s._id === staffMember._id);
                    return service ? service.services.reduce((acc, curr) => acc + curr.serviceCount, 0) : 0;
                });
                const services = staff.map(staffMember => {
                    const service = serviceData.find(s => s._id === staffMember._id);
                    return service ? service.services.map(s => ({ name: s.serviceName, count: s.serviceCount })) : [];
                });

                setStaffGraphData({
                    categories: staffNames,
                    series: totalServiceCounts,
                    services: services,
                });
            }
        } catch (error) {
            console.error(error);
            setStaffGraphData({ categories: [], series: [], services: [] });
        } finally {
            setIsLoading(false);
        }
    };

    const handleApply = () => {
        getStaffGraphData();  
    };

    return (
        <PageContainer title="All Work Analysis" description="this contains all products">
            <DashboardCard2 title="All Work Analysis">
                <div className="row" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <div className="col-2">
                        <FormControl fullWidth>
                            <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>Filter By</div>
                            <Select
                                name="Filter Options"
                                onChange={(e) => setFilterBy(e.target.value)}
                                value={filterBy}
                            >
                                {filterOptions.map((sourceOption) => (
                                    <MenuItem key={sourceOption.value} value={sourceOption.value}>
                                        {sourceOption.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {filterBy === 'customDate' && (
                        <>
                            <div className="col-2">
                                <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>From</div>
                                <input
                                    type="date"
                                    className="form-control"
                                    style={{ padding: '10px' }}
                                    name="from"
                                    onChange={(e) => setFrom(e.target.value)}
                                    value={from}
                                />
                            </div>
                            <div className="col-2">
                                <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>To</div>
                                <input
                                    type="date"
                                    max={new Date().toISOString().split('T')[0]}
                                    className="form-control"
                                    style={{ padding: '10px' }}
                                    name="to"
                                    onChange={(e) => setTo(e.target.value)}
                                    value={to}
                                />
                            </div>
                        </>
                    )}
                    <div className="col-2" style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Button
                            disabled={!!(filterBy === 'customDate' && (from === '' || to === ''))}
                            variant="contained"
                            color="primary"
                            sx={{
                                background: '#6174DD',
                                padding: '10px 40px',
                                marginTop: '1.5rem',
                            }}
                            onClick={handleApply}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
                <div className="chart-container">
                    <div>
                        <h4
                            style={{
                                textAlign: 'center',
                                fontSize: '20px',
                                color: 'green',
                                fontWeight: 'bold',
                                marginTop: '3rem',
                                marginRight: '-10rem',
                            }}
                        >
                            Staff Work Report
                        </h4>
                        <div style={{ marginLeft: '10rem' }}>
                            <StaffBarGraph data={staffGraphData} isLoading={isLoading} />
                        </div>
                    </div>
                </div>
            </DashboardCard2>
        </PageContainer>
    );
};

export default WorkAnalysis;
